import React, { FC, useEffect, useState, useRef, useContext } from "react";
import { IDelegationDashboardProps } from "../delegationDashboard.types";
import { injectIntl } from "react-intl";
import { messages } from "../delegationDashboard.messages";
import { Stack, Panel, IStackTokens, IStackStyles, IPersonaProps, MessageBar, MessageBarType, IStyle, IPersonaSharedProps, IBasePickerSuggestionsProps, Label, DatePicker, TimePicker, TooltipHost, TooltipDelay, DirectionalHint, TextField, PrimaryButton, NormalPeoplePicker, IconButton, DefaultButton, Dropdown, } from "@fluentui/react";
import { DetailsList, DetailsListLayoutMode, PanelType, SelectionMode, mergeStyleSets, Icon, IDropdownOption } from "office-ui-fabric-react";
import { getGraphUserData } from "../../services/graphapi";
import { DelegationAPI } from "../../services/delegateapi";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { v4 as uuidv4 } from "uuid";
import { isMockData, mockDataMessage } from "../../utils";
import TelemetryService from "../../services/TelemetryService/TelemetryService";
import TelemetryContext from "../../context/TelemetryContext";
import Enums from "../../services/TelemetryService/Enums";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import * as MarkdownIt from 'markdown-it';

const AddDelegateComponent: FC<
    IDelegationDashboardProps & { IsAddPanelOpen; onDismiss; getDelegation }
> = (props) => {
    const parentContext = props.parentContext;
    interface IExtendedDropdownOption extends IDropdownOption {
        id: string;
        supportedDisbursementTypeCode?: string[] | null;
    }
    const [selectedPersonDelegator, setSelectedPersonDelegator] =
        useState<IPersonaSharedProps>();
    const getStartDate = () => {
        let date = new Date();
        date.setHours(23);
        date.setMinutes(30)
        date.setSeconds(0)
        let currentDate = new Date()
        if (currentDate > date) {
            let newdate = new Date()
            let startDate = new Date(newdate.setDate(newdate.getDate() + 1));
            return startDate;
        }
        return new Date();
    }
    const markdown = MarkdownIt().set({html: true});
    const [currentTime, setCurrentTime] = React.useState<Date>();
    const [startTime, setStartTime] = React.useState(null);
    const [endTime, setEndTime] = React.useState(null);
    const [startDate, setStartDate] = useState(getStartDate());
    const [endDate, setEndDate] = useState(null);
    const [isCheckAdminUser, setIsCheckAdminUser] = useState(false);
    const formatTime = (timeString) => {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        let hourIn12formate = (hour % 12 || 12)
        return (hourIn12formate >= 10 ? hourIn12formate : '0' + hourIn12formate) + ":" + minute + (hour < 12 ? " AM" : " PM");
    };
    const generateTimeOptions = (isToday, startTime = null) => {
        const options = [];
        const now = new Date();
        const currentHour = now.getHours();
        for (let hour = 0; hour < 24; hour++) {
            if (
                !isToday ||
                hour > currentHour ||
                (startTime && hour > parseInt(startTime.split(':')[0]))
            ) {
                const time = new Date();
                time.setHours(hour, 0, 0, 0);
                options.push({
                    key: formatTime(time.toTimeString().substring(0, 5)),
                    text: formatTime(time.toTimeString().substring(0, 5)),
                });
            }
        }
        return options;
    };

    const generateEndTimeOptions = (isToday, selectedEndDate = null, startTime = null) => {
        const options = [];
        const now = new Date();
        if (selectedEndDate) {
            let starthours = startTime ? startTime.includes('PM') && parseInt(startTime.split(':')[0]) != 12
                ? parseInt(startTime.split(':')[0]) + 12
                : startTime.includes('PM') && parseInt(startTime.split(':')[0]) == 12 ? 12
                    : parseInt(startTime.split(':')[0]) == 12 ? 0 : parseInt(startTime.split(':')[0])
                : null;
            for (let hour = 0; hour < 24; hour++) {
                if (startDate && selectedEndDate && startDate.toDateString() == selectedEndDate.toDateString()) {
                    if (startTime && hour > starthours) {
                        const time = new Date();
                        let hours = (startTime.includes('PM') ? hour < 12 ? hour + 12 : hour : hour);
                        time.setHours(hours, 0, 0, 0);
                        options.push({
                            key: formatTime(time.toTimeString().substring(0, 5)),
                            text: formatTime(time.toTimeString().substring(0, 5)),
                        });
                    }
                }
                else if (!isToday) {
                    const time = new Date();
                    time.setHours(hour, 0, 0, 0);
                    options.push({
                        key: formatTime(time.toTimeString().substring(0, 5)),
                        text: formatTime(time.toTimeString().substring(0, 5)),
                    });
                }
            }
        }
        return options;
    };
    const [startOptions, setStartOptions] = useState(generateTimeOptions(false));
    const [endOptions, setEndOptions] = useState(generateEndTimeOptions(false));
    const [selectedPersonDelegate, setSelectedPersonDelegate] = useState<IPersonaSharedProps>();
    const [isAddPanelOpen, setIsAddPanelOpen] = useState(props.IsAddPanelOpen);
    const [loggedInUserNotes, setLoggedInUserNotes] = useState("");
    const [listPartnersSupported, setListPartnersSupported] = useState([]);
    const [supportedDisbursementTypeCode, setSupportedDisbursementTypeCode] = useState<string[]>([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [delegateerrorMessage, setDelegateerrorMessage] = useState("");
    const [delegateerrorMessageFlag, setDelegateerrorMessageFlag] = useState(false);
    const [delegatorerrorMessageFlag, setDelegatorerrorMessageFlag] = useState(false);
    const [delegatorerrorMessage, setDelegatorerrorMessage] = useState("");
    const [safeLimitDelegatRecords, setSafeLimitDelegatRecords] = useState([]);
    const [normalPeoplePickerErrorMessageDelegater, setNormalPeoplePickerErrorMessageDelegater] = useState(false);
    const [StartDateErrorMessage, setStartDateErrorMessage] = useState(false);
    const [EndDateErrorMessage, setEndDateErrorMessage] = useState(false);
    const [ApplicationErrorMessage, setApplicationErrorMessage] = useState(false);
    const [AddButtonErrorMessage, setAddButtonErrorMessage] = useState("");
    const [showSafeLimitMessage, setShowSafeLimitMessage] = useState(false);
    const [showSafeLimitTable, setShowSafeLimitTable] = useState(false);
    const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);
    const [isStartDateFocused, setisStartDateFocused] = useState(false);
    const [isEndDateFocused, setisEndDateFocused] = useState(false);
    const [consumerDocTypeId, setConsumerDocTypeId] = useState(null);
    const ismockdata = isMockData(parentContext);
    const { telemetryClient } = useContext(TelemetryContext);
    const [safeLimitDelegatorRecords, setSafeLimitDelegatorRecords] = useState([]); //
    const [normalPeoplePickerErrorMessageDelegator, setNormalPeoplePickerErrorMessageDelegator] = useState(false);
    const [selectpartnerErrorMessage, setselectpartnerErrorMessage] = useState(false);
    const [delegatorNoteErrorMessage, setdelegatorNoteErrorMessage] = useState(false);
    const [showSafeLimitMessageDelegator, setShowSafeLimitMessageDelegator] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState<IPersonaSharedProps>(null);
    const partnerOptions: IExtendedDropdownOption[] = listPartnersSupported.map((partner) => ({
        key: partner.partnerName,
        id: partner.consumerDocTypeId,
        text: partner.partnerName,
        supportedDisbursementTypeCode: partner.supportedDisbursementTypeCode, // Add this line
    }));
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState(null);
    const [showSafeLimitTableDelegator, setShowSafeLimitTableDelegator] = useState(false);
    const selectedPartnerObject = selectedPartner
        ? listPartnersSupported.find((partner) => partner.partnerName === selectedPartner)
        : null;
    const permissionOptions = selectedPartnerObject && Array.isArray(selectedPartnerObject.permissions)
        ? selectedPartnerObject.permissions.map((permission) => ({
            key: permission,
            text: permission,
            id: selectedPartnerObject.consumerDocTypeId,
            disabled: false
        })) : [];
    const handleDropdownChange = (event, option) => {
        const newSelectedPermissions = option.selected
            ? [...selectedPermissions, option.key]
            : selectedPermissions.filter((key) => key !== option.key);

        setSelectedPermissions(newSelectedPermissions);
        handlePermissionChange(
            option.selected,
            selectedPartner,
            selectedPartnerObject.consumerDocTypeId,
            option.key
        );
    };
    const [isActingAsAnAdmin, setIsActingAsAnAdmin] = useState(null); //null 
    const [isActingAsAnAdminText, setIsActingAsAnAdminText] = useState("");
    const [isCheckAdminUserFailError, setisCheckAdminUserFailError] = useState("");
    const [applicationId, setApplicationId] = useState("");
    const [msauthDeepLink, setMSAuthDeepLink] = useState("");
    const chechIsuserAdmin = (consumerDocTypeId, updatedsupportedDisbursementTypeCode) => {
        setDisableBtn(true);
        let api = new DelegationAPI();
        api.checkadminuser(parentContext, ismockdata, consumerDocTypeId)
            .then((res: any) => {
                if (res.status === 200) { 
                if (res.data.payload === null || !res.data.payload.actionResult) {
                    if (updatedsupportedDisbursementTypeCode && updatedsupportedDisbursementTypeCode.length > 0) {
                        setShowSafeLimitMessageDelegator(true);
                        checkSafeLimit(loggedInUser, consumerDocTypeId, "delegator");
                    } else { setShowSafeLimitMessageDelegator(false); }
                    setSelectedPersonDelegator(loggedInUser);
                    setIsActingAsAnAdmin(false);
                  setTimeout(() => {
                    setIsActingAsAnAdminText("You are not acting as an Admin");
                }, 100)
            }
                else {
                    setIsActingAsAnAdmin(true);
                  setTimeout(() => {
                    setIsActingAsAnAdminText("You are acting as an Admin");
                }, 100)
            }
            setIsCheckAdminUser(false);
            setisCheckAdminUserFailError("");
            setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: false });
            }
            // else{ 
            //        // Handle non-200 HTTP responses
            // setIsCheckAdminUser(true);
            // setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: true, message: res.message, messageType: MessageBarType.error });  
            // }
        })
            .catch((e: any) => {
             setIsCheckAdminUser(true);
             setisCheckAdminUserFailError(e.response.data);
             setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: true, message: e.response.data, messageType: MessageBarType.error })
            })
            .finally(() => {
                setDisableBtn(false);
            });
    }
    const stackStyles: Partial<IStackStyles> = { root: { width: 200 } }; // this is for pop up  add new delegate
    const stackTokens: IStackTokens = { childrenGap: 20 };
    const handleConfirm = () => {
        setShowPopup(false);
        proceedWithDelegation();
    };

    const handleCancel = () => {
        setShowPopup(false);
        setDisableBtn(false);
    };
    const ConfirmationPopup = ({ validationResult, onConfirm, onCancel, link }) => (
        <Dialog
            hidden={!validationResult[0].errorMessage}
            onDismiss={onCancel}
            dialogContentProps={{
                type: DialogType.normal,
                title: "",
                closeButtonAriaLabel: 'Close',
                subText: validationResult[0].errorMessage.split("\n").map((i, key) => {
                    return <b><div key={key}>{i}</div></b>;
                })
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            {<span>There is a mismatch with the combination of DisbursementTypeCode - Company Code such as [`<b style={{ fontSize: 12 }}>{validationResult[1].errorMessage}</b>`]</span>}<br /><br />
            {<span style={{ fontSize: 12 }}>Do you want to proceed? Click <b>'OK'</b> to continue or visit <b><i><a href={link} target="_blank">MSAuthorize</a></i></b> to update the Safelimit.</span>}
            <DialogFooter>
                <PrimaryButton onClick={onConfirm} text="OK" />
                <DefaultButton onClick={onCancel} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
    let logData: any = {};
    const checkSafeLimitvalidation = () => {
        let api = new DelegationAPI();
        TelemetryService.trackEvent(
            parentContext,
            telemetryClient,
            Enums.TrackingEvent.SafelimitvalidationProcessing,
            props.appName,
            logData
        );
        api.safelimitvalidation(applicationId, consumerDocTypeId, selectedPersonDelegator.secondaryText.split("@")[0], selectedPersonDelegate.secondaryText.split("@")[0])
            .then(res => {
                if (res.data.validationResult && res.data.validationResult.length > 0) {
                    setPopupMessage(res.data.validationResult);
                    setShowPopup(true);
                    setMSAuthDeepLink(res.data.msAuthDeepLink)
                } else {
                    setShowPopup(false);
                    proceedWithDelegation();
                }
            }
            )
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setShowMessageBar({ ...showMessageBar, show: true, message: error.response.data, messageType: MessageBarType.error });
                    setDisableBtn(false);
                    const stackTrace = error.stack || "";
                    const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
                    const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;
                    const exceptionType = error.message ? error.message.split(":")[0] : "";
                    TelemetryService.trackException(parentContext, telemetryClient, Enums.TrackingEvent.SafelimitvalidationFail, error.response ? error.response.data : "", exceptionType, failedMethod, props.appName);
                }
            });
    }
    const GetCurrentLoggedInUser = () => {
        const api = new DelegationAPI();
        api.getLoggedInUserDetail(parentContext).then(res => {
            if (res) {
                const loggedInUser: IPersonaSharedProps = {
                    text: res.text,
                    secondaryText: res.secondaryText,
                    optionalText: res.optionalText
                };
                setLoggedInUser(loggedInUser);
            }
        }).catch(error => {
        });
    }
    const dropdownRef = useRef(null);
    useEffect(() => {
        const isStartToday = startDate && new Date().toDateString() === startDate.toDateString();
        const isEndToday = endDate && new Date().toDateString() === endDate.toDateString();
        setStartOptions(generateTimeOptions(isStartToday));
        setEndOptions(generateEndTimeOptions(isEndToday, endDate, startTime));
        setTimeout(() => {
            if (dropdownRef.current) {
                dropdownRef.current.focus();
            }
            GetCurrentLoggedInUser();
        }, 200);
    }, []);
    const combineDateAndTime = (date: Date, time: string) => {
        if (!date || !time) return null;

        const [timestr, period] = time.split(' ');
        let [hours, minutes] = timestr.split(':').map(Number);
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        }
        if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        const combined = new Date(date);
        combined.setHours(hours);
        combined.setMinutes(minutes);
        return combined;
    };
    const [startCombinedDateTime, setStartCombinedDateTime] = React.useState(null);
    const [endCombinedDateTime, setEndCombinedDateTime] = React.useState(null);
    const maxEndDate = new Date(startDate);
    maxEndDate.setFullYear(maxEndDate.getFullYear() + 1);
    const today = new Date();
    const onSelectStartDate = (date) => {
        const dateStr = date ? date.toISOString() : "";
        setStartDate(date);
        setStartTime(null); // Reset start time when date changes
        setEndDate(date > endDate ? date : endDate); // Ensure end date is not before start date
        setEndTime(null); // Reset end time when start date changes
        setStartOptions(generateTimeOptions(date && new Date().toDateString() === date.toDateString()));
        setStartCombinedDateTime(null);
        setStartDateErrorMessage(false);
    };
    const onSelectEndDate = (date) => {
        setEndDate(date);
        setEndTime(null); // Reset end time when date changes
        setEndCombinedDateTime(null);
        setEndOptions(generateEndTimeOptions(date && new Date().toDateString() === date.toDateString(), date, startTime));
        setEndDateErrorMessage(false);
    };
    const onStartTimeChange = (event, option) => {
        setStartTime(option.key);
        setEndOptions(generateEndTimeOptions(endDate && new Date().toDateString() === endDate.toDateString(), endDate, option.key));
        if (endDate === startDate && (!endTime || option.key >= endTime)) {
            setEndTime(null);
        }
        const newCombinedDateTime = combineDateAndTime(
            startDate ? new Date(startDate) : null,
            option.key
        );
        setStartCombinedDateTime(newCombinedDateTime);
    };
    const onEndTimeChange = (event, option) => {
        setEndTime(option.key);
        setEndOptions(generateEndTimeOptions(endDate && new Date().toDateString() === endDate.toDateString(), endDate, startTime));
        const newCombinedDateTime = combineDateAndTime(
            endDate ? new Date(endDate) : null,
            option.key
        );
        setEndCombinedDateTime(newCombinedDateTime);
    };
    const columns = [
        {
            key: "userAlias",
            name: "Requester Email Alias",
            fieldName: "userAlias",
            isResizable: true,
            minWidth: 60,
            maxWidth: 70,
        },
        {
            key: "approvedSAFELimit",
            name: "Approved SAFE Limit",
            fieldName: "approvedSAFELimit",
            isResizable: true,
            minWidth: 60,
            maxWidth: 70,
        },
        {
            key: "approvedDate",
            name: "Approved Date",
            fieldName: "approvedDate",
            isResizable: true,
            minWidth: 60,
            maxWidth: 70,
        },
        {
            key: "approverAlias",
            name: "Approver Alias",
            fieldName: "approverAlias",
            isResizable: true,
            minWidth: 60,
            maxWidth: 70,
        },

        {
            key: "companyCode",
            name: "Company Code",
            fieldName: "companyCode",
            isResizable: true,
            minWidth: 60,
            maxWidth: 70,
        },
        {
            key: "disbursementTypeCode",
            name: "Disbursement Type Code",
            fieldName: "disbursementTypeCode",
            isResizable: true,
            minWidth: 100,
            onRender: (item) => {
                const tooltipId = `tooltip-${item.disbursementTypeCode}`; // Unique ID for the tooltip

                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Separate div for Disbursement Type Code */}
                        <div>
                            <span
                                tabIndex={0} // Makes the text focusable with keyboard
                                aria-label={item.disbursementTypeCode} // Ensures that the text is announced
                            >
                                {item.disbursementTypeCode}
                            </span>
                        </div>

                        {/* Separate div for the Tooltip Icon */}
                        <div style={{ marginLeft: '8px' }}>
                            <TooltipHost
                                content={item.disbursementTypeDescription} // Tooltip content
                                delay={TooltipDelay.zero}
                                directionalHint={DirectionalHint.topCenter}
                                id={tooltipId} // Unique ID for the tooltip
                            >
                                <button
                                style={{ background: "none", border: "none", padding: "0", cursor: "pointer" }}
                                    role="img"
                                    tabIndex={0} // Makes the icon focusable with the keyboard
                                    aria-labelledby={tooltipId} // Only announces the tooltip content when focused
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            e.preventDefault();
                                            const targetElement = e.target as HTMLElement;
                                            if (targetElement.click) {
                                                targetElement.click();
                                            }
                                        }
                                    }}
                                >
                                    <Icon iconName="Info" style={{ cursor: 'pointer' }} />
                                </button>
                            </TooltipHost>
                        </div>
                    </div>
                );
            },
        }
    ];
    const handlePanelDismiss = () => {
        // Close the panel and notify the parent component
        props.onDismiss();
    };

    // Function to check if at Date is null or empty
    const isStartDateSelected = () => {
        return startDate !== null;
    };

    // Dynamic message for accessibility live region for Start Date
    const requiredMessageforStartDate = isStartDateSelected()
        ? ""
        : "Start Date is required.";

    // JSX for the live region for Start Date
    const liveRegionforStartDate = (
        <div
            id="liveRegionforStartDate"
            aria-live="assertive"
            aria-atomic="true"
            style={{
                position: "absolute",
                overflow: "hidden",
                clip: "rect(0 0 0 0)",
                height: "1px",
                width: "1px",
                margin: "-1px",
                padding: "0",
                border: "0",
            }}
        >
            {requiredMessageforStartDate}
        </div>
    );

    // Function to check if at End Date is null or empty
    const isEndDateSelected = () => {
        return endDate !== "";
    };

    // Dynamic message for accessibility live region for Start Date
    const requiredMessageforEndDate = isEndDateSelected()
        ? ""
        : "End Date is required.";

    // JSX for the live region for Start Date
    const liveRegionforEndDate = (
        <div
            id="liveRegionforEndDate"
            aria-live="assertive"
            aria-atomic="true"
            style={{
                position: "absolute",
                overflow: "hidden",
                clip: "rect(0 0 0 0)",
                height: "1px",
                width: "1px",
                margin: "-1px",
                padding: "0",
                border: "0",
            }}
        >
            {requiredMessageforEndDate}
        </div>
    );

    useEffect(() => {
        let api = new DelegationAPI();
        let logData: any = {};

        TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.DelegationInfoProcessingAddDelegate, props.appName, logData)
        api
            .getDelegationInfo(
                parentContext,
                ismockdata
            )
            .then((res: any) => {
                setListPartnersSupported(res.data.partnersSupported);
                if (res && res.data.partnersSupported) {
                    for (let i = 0; i < res.data.partnersSupported.length; i++) {
                        let logData: any = {};
                        logData[Enums.LogDataKey.AppName] = res.data.appName;
                        logData[Enums.LogDataKey.AppClientId] = res.data.appClientId;
                        logData[Enums.LogDataKey.PartnerName] =
                            res.data.partnersSupported[i].partnerName;
                        logData[Enums.LogDataKey.Permissions] =
                            res.data.partnersSupported[i].permissions;
                        logData[Enums.LogDataKey.ConsumerDocTypeId] =
                            res.data.partnersSupported[i].consumerDocTypeId;
                        logData[Enums.LogDataKey.supportedDisbursementTypeCode] =
                            res.data.partnersSupported[i].supportedDisbursementTypeCode;
                        TelemetryService.trackEvent(
                            parentContext,
                            telemetryClient,
                            Enums.TrackingEvent.DelegationInfoSuccessAddDelegate,
                            props.appName,
                            logData
                        );
                    }
                }
            })
            .catch((e: any) => {
                const stackTrace = e.stack || "";
                const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
                const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;
                // Extracting Exception type
                const exceptionType = e.message ? e.message.split(":")[0] : "";
                TelemetryService.trackException(
                    parentContext,
                    telemetryClient,
                    Enums.TrackingEvent.DelegationInfoFailAddDelegate,
                    e.response ? e.response.data : "",
                    exceptionType,
                    failedMethod,
                    props.appName
                );
            });
        
        if (isStartDateFocused && !isStartDateSelected()) {
            document.getElementById("liveRegionforStartDate").innerText =
                "Start Date is required.";
        }
        if (isEndDateFocused && !isEndDateSelected()) {
            document.getElementById("liveRegionforEndDate").innerText =
                "End Date is required.";
        }
        // eslint-disable-next-line
    }, [
        parentContext,
        isAddPanelOpen,
        isCheckboxFocused,
        isStartDateFocused,
        isEndDateFocused,
    ]);

    const notesStyles = mergeStyleSets({
        styles: { width: 260, marginBottom: 5 },
    });
    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }
    const onChangeSelectionDelegator = (items) => {
        if (items && items.length > 0) {
            const personDetail: IPersonaSharedProps = {
                text: items[0].text,
                secondaryText: items[0].secondaryText,
                optionalText: items[0].optionalText,

            };
            setSelectedPersonDelegator(personDetail);
            if (selectedPersonDelegate && selectedPersonDelegate.secondaryText != undefined && selectedPersonDelegate.secondaryText != null) {
                if (personDetail && personDetail.secondaryText === selectedPersonDelegate.secondaryText) {
                    setDelegatorerrorMessage("Delegate and Delegator cannot be the same person");
                    setDelegatorerrorMessageFlag(true);
                    return;
                }
            }
            if (isActingAsAnAdmin) {
                if (items.length > 0) {
                    setNormalPeoplePickerErrorMessageDelegator(false);
                } else {
                    setNormalPeoplePickerErrorMessageDelegator(true);
                }
            }
            if (supportedDisbursementTypeCode && supportedDisbursementTypeCode.length > 0) {
                setShowSafeLimitMessageDelegator(true);
                checkSafeLimit(personDetail, consumerDocTypeId, "delegator");
            }
        }
        else {
            setNormalPeoplePickerErrorMessageDelegator(false);
            setSelectedPersonDelegator(null);
            setDelegatorerrorMessage("");
            setDelegatorerrorMessageFlag(false);
            setShowSafeLimitMessageDelegator(false);
            setSafeLimitDelegatorRecords([]);
            setShowSafeLimitTableDelegator(false);

        }
    };
    const onChangeSelectionDelegate = (items) => {
        if (items && items.length > 0) {
            setNormalPeoplePickerErrorMessageDelegater(false);
            const personDetail: IPersonaSharedProps = {
                text: items[0].text,
                secondaryText: items[0].secondaryText,
                optionalText: items[0].optionalText,
            };
            setSelectedPersonDelegate(personDetail);
            if (selectedPersonDelegator && selectedPersonDelegator.secondaryText != undefined && selectedPersonDelegator.secondaryText != null) {
                if (personDetail && personDetail.secondaryText === selectedPersonDelegator.secondaryText) {
                    setDelegateerrorMessage("Delegate and Delegator cannot be the same person");
                    setDelegateerrorMessageFlag(true);
                    return;
                }
            }
            if (personDetail && personDetail.secondaryText === loggedInUser.secondaryText) {
                setDelegateerrorMessage("Delegate and LoginUser cannot be the same person");
                setDelegateerrorMessageFlag(true);
                return;
            }
            if (supportedDisbursementTypeCode && supportedDisbursementTypeCode.length > 0) {
                setShowSafeLimitMessage(true); // safe limit message not displayed noe
                checkSafeLimit(personDetail, consumerDocTypeId, "delegate");
            }
        } else {
            setNormalPeoplePickerErrorMessageDelegater(false);
            setSelectedPersonDelegate(null);
            setDelegateerrorMessage("");
            setDelegateerrorMessageFlag(false);
            setShowSafeLimitMessage(false);
            setSafeLimitDelegatRecords([]);
            setShowSafeLimitTable(false);
        }
    };

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: "Suggested People",
        noResultsFoundText: "No results found",
        loadingText: "Loading...",
        showRemoveButtons: false,
        suggestionsAvailableAlertText: "People Picker Suggestions available",
        suggestionsContainerAriaLabel: "Suggested contacts",
        removeButtonIconProps: { iconName: "ProfileSearch" },
        suggestionsClassName: "",
    };

    const onFilterChanged = (
        filterText: string
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            return new Promise((resolve) => {
                try {
                    getGraphUserData(filterText, parentContext).then((result) =>
                        resolve(
                            result.value.map((item: any) => {
                                return {
                                    text: item.displayName,
                                    secondaryText: item.userPrincipalName,
                                    optionalText: item.id,
                                };
                            })
                        )
                    );
                } catch {
                    resolve([]);
                }
            });
        } else {
            return [];
        }
    };
    //#endregion

    const selectNotes = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newText: string
    ): void => {
        setLoggedInUserNotes(newText);
        setdelegatorNoteErrorMessage(false);
    };

    const [showMessageBar, setShowMessageBar] = useState({
        show: false,
        message: "",
        messageType: MessageBarType.error,
    });

    const onDismissAlert = () => {
        setShowMessageBar({ ...showMessageBar, show: false });
    };
    const [showMessageBarIsCheckAdmin, setShowMessageBarIsCheckAdmin] = useState({
        show: false,
        message: "",
        messageType: MessageBarType.error,
    });
    const onDismissAlertIsCheckAdmin = () => {
        setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: false });
    };
    const [disableBtn, setDisableBtn] = useState(false);
    const personaStyle = mergeStyleSets({
        persona: {
            marginTop: 10,
            marginBottom: 15,
            borderRadius: 15,
            border: "0px solid #c8c6c4",
            background: "#605e5d1c",
            height: 60,
        } as IStyle,
        rectangle: {
            position: "relative",
            padding: 5,
            width: 230,
            height: 500,
        } as IStyle,
        bText: {
            marginLeft: 5,
            fontSize: 18,
            fontWeight: 600,
            marginBottom: 15,
        } as IStyle,
        loaderContainer: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // Add any other necessary styles for the loader container
        } as IStyle,
        disablepointerevents: { pointerEvents: "none" } as IStyle,
    });

    const label = "Wait..."
    let submitloader = disableBtn ? (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
                backgroundColor: "rgba(229, 229, 229, 0.5)",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spinner
                id="addNewdelegate-spinner"
                label={label}
                labelPosition="right"
                size={SpinnerSize.large}
            />
        </div>
    ) : (
        ""
    );
    const checkSafeLimit = (personDetail, consumerDocTypeId, value) => {
        const ismockdata = isMockData(parentContext);
        let api = new DelegationAPI();
        let logData: any = {};
        TelemetryService.trackEvent(
            parentContext,
            telemetryClient,
            Enums.TrackingEvent.GetSafeLimitsProcessingAddDelegate,
            props.appName,
            logData
        );
        api
            .safeLimitData(
                parentContext,
                personDetail.secondaryText
                    ? personDetail.secondaryText.split("@")[0]
                    : "",
                ismockdata,
                consumerDocTypeId
            )
            .then((res: any) => {
                // Handle the response as needed
                if (value === "delegator") {
                    setSafeLimitDelegatorRecords(res.data);
                    if (res && res.data) {
                        logData[Enums.LogDataKey.DelegateUserAlias] =
                            personDetail.secondaryText
                                ? personDetail.secondaryText.split("@")[0]
                                : "";
                        logData[Enums.LogDataKey.safeLimitData] = res.data;
                    }
                } else {
                    setSafeLimitDelegatRecords(res.data);
                    if (res && res.data) {
                        logData[Enums.LogDataKey.DelegateUserAlias] =
                            personDetail.secondaryText
                                ? personDetail.secondaryText.split("@")[0]
                                : "";
                        logData[Enums.LogDataKey.safeLimitData] = res.data;
                    }
                }
                TelemetryService.trackEvent(
                    parentContext,
                    telemetryClient,
                    Enums.TrackingEvent.GetSafeLimitsSuccessAddDelegate,
                    props.appName,
                    logData
                );
            })
            .catch((error) => {
                const stackTrace = error.stack || "";
                const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
                const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;
                // Extracting Exception type
                const exceptionType = error.message
                    ? error.message.split(":")[0]
                    : "";
                TelemetryService.trackException(
                    parentContext,
                    telemetryClient,
                    Enums.TrackingEvent.GetSafeLimitsFailureAddDelegate,
                    error.response ? error.response.data : "",
                    exceptionType,
                    failedMethod,
                    props.appName
                );
            });
    }
    const ResetAllControl = () => {
        setSelectedPermissions([]);
        setSelectedPersonDelegate(null);
        setSafeLimitDelegatRecords([]);
        setSelectedItems([]);
        if (isActingAsAnAdmin && supportedDisbursementTypeCode && supportedDisbursementTypeCode.length > 0) {
            setShowSafeLimitMessageDelegator(false)
        }
        setSelectedPersonDelegator(null);
        setSafeLimitDelegatorRecords([]);
        setShowSafeLimitMessage(false);
        setStartDate(getStartDate());
        setEndDate(null);
        setStartCombinedDateTime(null);
        setEndCombinedDateTime(null);
        if (startOptions.length > 0) {
            setStartTime(startOptions[0].key);
        }
        else {
            setStartTime(null);
        }
        setEndTime(null);
        setLoggedInUserNotes("");
        setShowSafeLimitTable(false);
        setShowSafeLimitTableDelegator(false)
    }
    const submitDelegation = async () => {
        let isValidation=false;
        const isValueSelected = selectedPartner !== null && selectedPartner !== undefined;
        if (!isValueSelected) {
            setselectpartnerErrorMessage(true);
            isValidation=true;
        }
        if(isCheckAdminUser)
            {
                setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: true, message: isCheckAdminUserFailError, messageType: MessageBarType.error });
                isValidation=true;
            }
        if (isValueSelected && !isCheckAdminUser) {
            if (selectedItems.length <= 0) {
                setApplicationErrorMessage(true);
                isValidation=true;
            }
            if ((selectedPersonDelegator === null || selectedPersonDelegator === undefined) && isActingAsAnAdmin) {
                setNormalPeoplePickerErrorMessageDelegator(true);
                isValidation=true;
            }
            if (selectedPersonDelegate === null || selectedPersonDelegate === undefined) {
                setNormalPeoplePickerErrorMessageDelegater(true);
                isValidation=true;
            }
            if (delegateerrorMessage != "") {
                setDelegateerrorMessageFlag(true);
                isValidation=true;
            }
            if (delegatorerrorMessage != "") {
                setDelegatorerrorMessageFlag(true);
                isValidation=true;
            }
            if (startDate === null) {
                setStartDateErrorMessage(true);
                isValidation=true;
            }
            if (endDate === null) {
                setEndDateErrorMessage(true);
                isValidation=true;
            }
            if (isActingAsAnAdmin && loggedInUserNotes === "") {
                setdelegatorNoteErrorMessage(true);
                isValidation=true;
            }
        }
        if(isValidation){
            return;
        }
        onDismissAlert();
        setDisableBtn(true);
        if (supportedDisbursementTypeCode && supportedDisbursementTypeCode.length > 0) {
            checkSafeLimitvalidation();
        }
        else { proceedWithDelegation(); }
    };
    const proceedWithDelegation = () => {
        const createDelegateInfo = (delegate, startDate, endDate, delegator, LoggedInUserNotes, PartnerName, Permissions, ConsumerDocTypeId, Xcv, Tcv, ApplicationId) => {
            return { delegate, startDate, endDate, LoggedInUserNotes, delegator, PartnerName, Permissions, ConsumerDocTypeId, Xcv, Tcv, ApplicationId };
        };
        const createDelegationPayload = selectedItems.map((item) => {
            const delegate = { Email: selectedPersonDelegate.secondaryText, Id: selectedPersonDelegate.optionalText, Name: selectedPersonDelegate.text, Alias: selectedPersonDelegate.secondaryText.includes("@") ? selectedPersonDelegate.secondaryText.split("@")[0] : selectedPersonDelegate.secondaryText };
            const delegator = { Email: selectedPersonDelegator.secondaryText, Id: selectedPersonDelegator.optionalText, Name: selectedPersonDelegator.text, Alias: selectedPersonDelegator.secondaryText.includes("@") ? selectedPersonDelegator.secondaryText.split("@")[0] : selectedPersonDelegator.secondaryText };
            const xcv = uuidv4();
            const tcv = uuidv4();
            let startDateTime = startTime ? combineDateAndTime(startDate, startTime) : startDate;
            let endDateTime = endTime ? combineDateAndTime(endDate, endTime) : endDate;
            return createDelegateInfo(delegate, startDateTime, endDateTime, delegator, loggedInUserNotes, item.partnerName, item.permissions, item.consumerDocTypeId, xcv, tcv, item.applicationId);
        });
        const api = new DelegationAPI();
        const ismockdata = isMockData(parentContext);
        if (ismockdata) {
            // setShowMessageBar({ ...showMessageBar, show: true, message: "Delegate Added Successfully.", messageType: MessageBarType.success });
            alert(mockDataMessage);
            setDisableBtn(false);
            props.onDismiss();
            setIsAddPanelOpen(false);
            return;
        }
        TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.CreateDelegationProcessing, props.appName, {});
        api.createDelegation(parentContext, createDelegationPayload)
            .then((res) => {
                if (res.status === 200) {
                    // setShowMessageBar({ ...showMessageBar, show: true, message: "Delegate Added Successfully.", messageType: MessageBarType.success });
                    setDisableBtn(false);
                    props.onDismiss();
                    setIsAddPanelOpen(false);
                    props.getDelegation(props.parentContext);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setShowMessageBar({ ...showMessageBar, show: true, message: error.response.data, messageType: MessageBarType.error });
                    setDisableBtn(false);
                    setIsAddPanelOpen(true);
                    const stackTrace = error.stack || "";
                    const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
                    const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;
                    const exceptionType = error.message ? error.message.split(":")[0] : "";
                    TelemetryService.trackException(parentContext, telemetryClient, Enums.TrackingEvent.CreateDelegationsFail, error.response ? error.response.data : "", exceptionType, failedMethod, props.appName);
                }
            });
    };

    const handlePermissionChange = (checked, partnerName, consumerDocTypeId, permissions) => {
        let updatedItems = [...selectedItems];
        const existingItemIndex = updatedItems.findIndex(item => item.partnerName === partnerName);

        if (checked) {
            if (existingItemIndex !== -1) {
                // Application name already exists, add permission to existing entry
                if (!updatedItems[existingItemIndex].permissions.includes(permissions)) {
                    updatedItems[existingItemIndex].permissions.push(permissions);
                }
            } else {
                // Application name doesn't exist, create a new entry
                const newItem = {
                    partnerName,
                    permissions: [permissions],
                    consumerDocTypeId,
                };
                updatedItems = [...updatedItems, newItem];
            }
        } else {
            if (existingItemIndex !== -1) {
                // Remove the permission from the existing entry
                updatedItems[existingItemIndex].permissions = updatedItems[existingItemIndex].permissions.filter(perm => perm !== permissions);

                // Remove the entry if no permissions are left
                if (updatedItems[existingItemIndex].permissions.length === 0) {
                    updatedItems.splice(existingItemIndex, 1);
                }
            }
        }

        setSelectedItems(updatedItems);

        // Validate if at least one checkbox is selected for at least one application
        const hasAtLeastOnePermission = updatedItems.some(item => item.permissions.length > 0);

        if (hasAtLeastOnePermission) {
            setApplicationErrorMessage(false);
        }
    };

    const containerStyle = {
        display: "flex",
        alignItems: "center",
    };
    const formatDate = (date) => {
        const formattedDate = new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: "2-digit",
            minute: "2-digit",
        });
        return formattedDate;
    };

    const isDelegationScope = (
        <div className={personaStyle.rectangle}>
            {submitloader}

          {isActingAsAnAdmin !== null && (
                <div style={containerStyle}>
                    <div style={{ marginTop: "5px" }}>
 <Label style={{color: '#9d6500', fontWeight: 500,backgroundColor:"#ffffff" }}
                    aria-label={isActingAsAnAdminText}
                    aria-live='polite'
                    >{isActingAsAnAdminText}</Label>
                    </div>
                </div>
            )}
            {showPopup && (
                <ConfirmationPopup
                    validationResult={popupMessage}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    link={msauthDeepLink}
                />
            )}
            <div style={{ marginTop: "5px",marginBottom: "3px"  }}>
            {showMessageBarIsCheckAdmin.show && (
                <div style={{ width: "260px" }}>
                    <MessageBar
                        messageBarType={showMessageBarIsCheckAdmin.messageType}
                        onDismiss={onDismissAlertIsCheckAdmin}
                        isMultiline={true}
                        dismissButtonAriaLabel="Close"
                        role="status"
                        aria-live="assertive"
                    >
                       <div dangerouslySetInnerHTML={{__html: markdown.render(showMessageBarIsCheckAdmin.message)}} ></div>
                    </MessageBar>
                </div>
            )}
            </div>
            <div style={{ marginTop: "5px" }}>
                <div style={containerStyle}>
                    <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
                        {props.intl.formatMessage(messages.addNewDelegateDelegateApplication)}
                    </Label>
                </div>
                <style>
        {`
          .ms-Dropdown.is-required::before {
            content: none !important; /* Removes the required asterisk */
          }
        `}
      </style>
                <Dropdown
                    componentRef={dropdownRef}
                    placeholder="Select an Application"
                    options={partnerOptions}
                    selectedKey={selectedPartner}
                    onChange={(e, option: IExtendedDropdownOption) => {
                        setSelectedPartner(option.key.toString());
                        setConsumerDocTypeId(option.id); // This is the consumer doc type id
                        setApplicationId(option.id); // We need to set the application id
                        setselectpartnerErrorMessage(false);
                        var updatedsupportedDisbursementTypeCode = option.supportedDisbursementTypeCode;
                        // Check if supportedDisbursementTypeCode is null or undefined before setting state
                        setSupportedDisbursementTypeCode(updatedsupportedDisbursementTypeCode ?? []); // Set an empty array if null or undefined
                        chechIsuserAdmin(option.id, updatedsupportedDisbursementTypeCode);
                        ResetAllControl();
                    }}
                    ariaLabel="Application"
                    aria-required="true"
                    styles={{ dropdown: { width: 260 }, dropdownOptionText:{whiteSpace: 'normal'}, title:{whiteSpace: 'normal'}}}
                    required={!selectedPartner}
                />


                {selectpartnerErrorMessage && (
                    <div style={{ color: "#e50000" }} aria-live="assertive"  role="alert" >
                    Please select an Application
                </div>
                )}
            </div>
            {(selectedPartner && !isCheckAdminUser) && (
                <div style={{ marginTop: "5px" }}>
                    <div style={containerStyle}>
                        <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
                            {props.intl.formatMessage(messages.addNewDelegateDelegatePermissions)}
                        </Label>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Dropdown
                            aria-labelledby="permissionDropdown"
                            multiSelect={true}
                            placeholder="Select permissions"
                            selectedKeys={selectedPermissions}
                            onChange={handleDropdownChange}
                            onFocus={() => setIsCheckboxFocused(true)} // Update focus state
                            onBlur={() => setIsCheckboxFocused(false)} // Update focus state
                            options={permissionOptions.map((option) => ({
                                key: option.key,
                                text: option.text,
                                'aria-labelledby': `checkboxLabel-${selectedPartner}-${option.key}`,
                                'aria-describedby': `checkboxDescription-${selectedPartner}-${option.key}`,
                                'aria-label': `${selectedPartner} application - permissions is ${option.key}`
                            }))}
                            ariaLabel="Permissions"
                            styles={{ dropdown: { width: 275, marginTop: 5 }}}
                            required={!selectedPermissions.length}
                            aria-required="true"
                        />
                    </div>
                    {ApplicationErrorMessage && (
                        <div style={{ color: "#e50000" }} aria-live="assertive"  role="alert" >
                        Please select permission
                    </div>
                    )}
                </div>
            )}
           
            <div style={{ marginTop: "5px" }}>
                <div style={containerStyle}>
                    <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
                        {props.intl.formatMessage(
                            messages.addNewDelegatorDelegatorNormalPeoplePickerr
                        )}
                    </Label>
                </div>
                <NormalPeoplePicker
                    onResolveSuggestions={onFilterChanged}
                    onChange={(event) => onChangeSelectionDelegator(event)}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={suggestionProps}
                    className={"ms-PeoplePicker"}
                    key={"normal"}
                    selectionAriaLabel={"Selected contacts"}
                    removeButtonAriaLabel={
                        selectedPersonDelegator && selectedPersonDelegator.text
                            ? `Remove ${selectedPersonDelegator.text}`
                            : "Remove selected Delegator"
                    }
                    inputProps={{
                        "aria-label": "Delegator",
                        placeholder: "Add a delegator",
                        "aria-required": true,
                    }}
                    resolveDelay={300}
                    itemLimit={1}
                    selectedItems={!selectedPartner ? [] : (!isActingAsAnAdmin && loggedInUser) ? [loggedInUser] : (selectedPersonDelegator ? [selectedPersonDelegator] : [])}
                    disabled={!selectedPartner || !isActingAsAnAdmin || isCheckAdminUser}
                    styles={{ root: { width: 275 } }} // Adjust the width here
                />
                {showSafeLimitMessageDelegator && (
                    <div>
                        <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            onClick={() => {
                                setShowSafeLimitTableDelegator(!showSafeLimitTableDelegator);
                            }}
                        >
                            <IconButton
                                iconProps={{
                                    iconName: showSafeLimitTableDelegator ? "ChevronDown" : "ChevronRight",
                                    style: {
                                        color: "rgb(50, 49, 48)", // Set the color here
                                        cursor: "pointer",
                                        background: "none",
                                    },
                                }}
                                aria-expanded={showSafeLimitTableDelegator}
                                aria-controls="safeLimitTable"
                                aria-label={showSafeLimitTableDelegator ? "Expand Show Safe Limit button" : "Collapse Show Safe Limit button"}

                                onClick={() => {
                                    setShowSafeLimitTableDelegator(!showSafeLimitTableDelegator);
                                }}
                            />
                            <span
                                style={{
                                    fontWeight: "600",
                                    color: "rgb(50, 49, 48)",
                                    fontSize: "14px",
                                    fontFamily:
                                        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                }}
                            >
                                Show Safe Limit
                            </span>
                        </div>
                        {showSafeLimitTableDelegator && (
                            <div
                                id="safeLimitTable"
                                style={{
                                    display: "flex",
                                    overflowX: "auto",
                                    maxHeight: "160px",
                                    width: "600px",
                                    overflowY: "auto",
                                }}
                            >
                                {safeLimitDelegatorRecords.length > 0 ? (
                                    <DetailsList
                                        role="grid"
                                        aria-label="Safe Limit Details List"
                                        ariaLabelForGrid="Safe Limit Details List"
                                        items={safeLimitDelegatorRecords.map(
                                            ({
                                                userAlias,
                                                approvedSAFELimit,
                                                isoCurrencyCode,
                                                approvedDate,
                                                approverAlias,
                                                companyCode,
                                                disbursementTypeCode,
                                                disbursementTypeDescription,
                                            }) => {
                                                const formattedApprovedSAFELimit = parseFloat(
                                                    approvedSAFELimit
                                                ).toLocaleString(undefined, {
                                                    maximumFractionDigits: 0,
                                                });

                                                return {
                                                    userAlias,
                                                    approvedSAFELimit: `${formattedApprovedSAFELimit} ${isoCurrencyCode}`,
                                                    approvedDate: formatDate(approvedDate),
                                                    approverAlias,
                                                    companyCode,
                                                    disbursementTypeCode,
                                                    disbursementTypeDescription,
                                                };
                                            }
                                        )}
                                        columns={columns}
                                        onRenderDetailsHeader={(headerProps, defaultRender) => {
                                            if (!headerProps || !defaultRender) {
                                                return null;
                                            }
                                            return defaultRender({
                                                ...headerProps,
                                                styles: {
                                                    root: {
                                                        selectors: {
                                                            ".ms-DetailsHeader-cell": {
                                                                whiteSpace: "normal",
                                                                textOverflow: "clip",
                                                                lineHeight: "normal",
                                                            },
                                                            ".ms-DetailsHeader-cellTitle": {
                                                                height: "100%",
                                                                alignItems: "center",
                                                            },

                                                        },
                                                    },
                                                },
                                            });
                                        }}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                        selectionMode={SelectionMode.none}

                                        styles={{
                                            root: { overflowY: "auto", overflowX: "auto" }, // Apply both vertical and horizontal scrollbars
                                        }}

                                    />
                                ) : (
                                <div style={{ textAlign: "center", marginTop: "20px" }} aria-live="assertive" role="alert">
                                        <p>Data Not Found</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div
                    aria-live="assertive"
                    aria-atomic="true"

                >
                    {delegatorerrorMessageFlag  && (
            <div className="error" style={{ color: "#e50000" }} aria-live="assertive" role="alert">
                            {delegatorerrorMessage}
                        </div>
                    )}
                    {normalPeoplePickerErrorMessageDelegator && isActingAsAnAdmin && (
  <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
  Please select a delegator.
</div>
                    )}
                </div>
            </div>
            <div style={{ marginTop: "5px" }}>
                <div style={containerStyle}>
                    <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
                        {props.intl.formatMessage(
                            messages.addNewDelegateDelegateNormalPeoplePicker
                        )}
                    </Label>
                </div>
                <NormalPeoplePicker
                    onResolveSuggestions={onFilterChanged}
                    onChange={(event) => onChangeSelectionDelegate(event)}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={suggestionProps}
                    className={"ms-PeoplePicker"}
                    key={"delegate"}
                    selectionAriaLabel={"Selected contacts"}
                    removeButtonAriaLabel={
                        selectedPersonDelegate && selectedPersonDelegate.text
                            ? `Remove ${selectedPersonDelegate.text}`
                            : "Remove selected delegate"
                    }
                    inputProps={{
                        "aria-label": "Delegate",
                        placeholder: "Add a delegate",
                        // autoFocus: true,
                        "aria-required": true,
                    "aria-live":'assertive',
                }}
                    resolveDelay={300}
                    itemLimit={1}
                    selectedItems={selectedPersonDelegate ? [selectedPersonDelegate] : []} //
                    disabled={!selectedPartner || isCheckAdminUser}
                    styles={{ root: { width: 275 } }} // Adjust the width here
                />
                {showSafeLimitMessage && (
                    <div>
                        <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            onClick={() => {
                                setShowSafeLimitTable(!showSafeLimitTable);
                            }}
                        >
                            <IconButton
                                iconProps={{
                                    iconName: showSafeLimitTable ? "ChevronDown" : "ChevronRight",
                                    style: {
                                        color: "rgb(50, 49, 48)", // Set the color here
                                        cursor: "pointer",
                                        background: "none",
                                    },
                                }}
                                aria-expanded={showSafeLimitTable}
                                aria-controls="safeLimitTable"
                                aria-label={showSafeLimitTable ? "Expand Show Safe Limit button" : "Collapse Show Safe Limit button"}

                                onClick={() => {
                                    setShowSafeLimitTable(!showSafeLimitTable);
                                }}
                            />
                            <span
                                style={{
                                    fontWeight: "600",
                                    color: "rgb(50, 49, 48)",
                                    fontSize: "14px",
                                    fontFamily:
                                        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                }}
                            >
                                Show Safe Limit
                            </span>
                        </div>
                        {showSafeLimitTable && (
                            <div
                                id="safeLimitTable"
                                style={{
                                    display: "flex",
                                    overflowX: "auto",
                                    maxHeight: "160px",
                                    width: "600px",
                                    overflowY: "auto",
                                }}
                            >
                                {safeLimitDelegatRecords.length > 0 ? (
                                    <DetailsList
                                        role="grid"
                                        aria-label="Safe Limit Details List"
                                        items={safeLimitDelegatRecords.map(
                                            ({
                                                userAlias,
                                                approvedSAFELimit,
                                                isoCurrencyCode,
                                                approvedDate,
                                                approverAlias,
                                                companyCode,
                                                disbursementTypeCode,
                                                disbursementTypeDescription,
                                            }) => {
                                                const formattedApprovedSAFELimit = parseFloat(
                                                    approvedSAFELimit
                                                ).toLocaleString(undefined, {
                                                    maximumFractionDigits: 0,
                                                });

                                                return {
                                                    userAlias,
                                                    approvedSAFELimit: `${formattedApprovedSAFELimit} ${isoCurrencyCode}`,
                                                    approvedDate: formatDate(approvedDate),
                                                    approverAlias,
                                                    companyCode,
                                                    disbursementTypeCode,
                                                    disbursementTypeDescription,
                                                };
                                            }
                                        )}
                                        columns={columns}
                                        onRenderDetailsHeader={(headerProps, defaultRender) => {
                                            if (!headerProps || !defaultRender) {
                                                return null;
                                            }
                                            return defaultRender({
                                                ...headerProps,
                                                styles: {
                                                    root: {
                                                        selectors: {
                                                            ".ms-DetailsHeader-cell": {
                                                                whiteSpace: "normal",
                                                                textOverflow: "clip",
                                                                lineHeight: "normal",
                                                            },
                                                            ".ms-DetailsHeader-cellTitle": {
                                                                height: "100%",
                                                                alignItems: "center",
                                                            },
                                                        },
                                                    },
                                                },
                                            });
                                        }}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                        selectionMode={SelectionMode.none}
                                        styles={{
                                            root: { overflowY: "auto", overflowX: "auto" }, // Apply both vertical and horizontal scrollbars
                                        }}
                                    />
                                ) : (
                                <div style={{ textAlign: "center", marginTop: "20px" }} aria-live="assertive" role="alert">
                                        <p>Data Not Found</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div
                    aria-live="assertive"
                    aria-atomic="true"

                >
                    {delegateerrorMessageFlag && (
            <div className="error" style={{ color: "#e50000" }} aria-live="assertive" role="alert">
            {delegateerrorMessage}
          </div>
                    )}
                    {normalPeoplePickerErrorMessageDelegater && (
    <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">Please select a delegate.</div>
                    )}
                </div>
            </div>
            <div style={{ marginTop: "5px" }}>
                <div style={containerStyle}>
                    <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
                        {props.intl.formatMessage(messages.addNewDelegateDelegateStartDate)}
                    </Label>
                </div>
                <Stack tokens={stackTokens} styles={stackStyles}>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridColumnGap: "0px",
                            width: "200px",
                        }}
                    >
                        <DatePicker
                            placeholder="Date"
                            isRequired
                            value={startDate}
                            onSelectDate={onSelectStartDate}
                            ariaLabel="Start Date"
                            minDate={new Date()}
                            styles={{ root: { width: 260, marginRight: "-10"} }}
                            disabled={!selectedPartner || isCheckAdminUser}
                        />
                        <div style={{ marginLeft: "-65px", width: "100px" }}>
                            <Dropdown
                                placeholder="Time"
                                options={startOptions}
                                selectedKey={startTime}
                                onChange={onStartTimeChange}
                                disabled={!selectedPartner || isCheckAdminUser}
                                ariaLabel="Start Time"
                                styles={{ root: { width: 100, marginLeft: "-20px" } }}
                            />
                        </div>
                        {startCombinedDateTime && (
                            <span
                                style={{ fontSize: "10px" }}
                            >{`📅  ${startCombinedDateTime.toString()}`}</span>
                        )}
                    </div>
                </Stack>
                {StartDateErrorMessage && (
          <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
          Start date is required.
        </div>
                )}
            </div>
            {liveRegionforStartDate}
            <div style={{ marginTop: "5px" }}>
                {/* <div style={{ marginBottom: "15px" }}></div> */}
                <div style={containerStyle}>
                    <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
                        {props.intl.formatMessage(messages.addNewDelegateDelegateEndDate)}
                    </Label>
                </div>

                {/* // fulent ui copilot panel */}
                <Stack tokens={stackTokens} styles={stackStyles}>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridColumnGap: "0px",
                            width: "200px",
                        }}
                    >
                        <DatePicker
                            placeholder="Date"
                            isRequired
                            value={endDate}
                            onSelectDate={onSelectEndDate}
                            ariaLabel="End Date"
                            minDate={startDate ? new Date(startDate) : today}
                            maxDate={maxEndDate}
                            styles={{ root: { width: 260, marginRight: "-10"}}}
                            disabled={!selectedPartner || isCheckAdminUser} // Disable the end date picker until a start date is selected
                        />
                        <div style={{ marginLeft: "-65px", width: "100px" }}>
                            <Dropdown
                                placeholder="Time"
                                options={endOptions}
                                selectedKey={endTime}
                                onChange={onEndTimeChange}
                                disabled={!selectedPartner || isCheckAdminUser}
                                ariaLabel="End Time"
                                styles={{ root: { width: 100, marginLeft: "-20px" } }}
                                aria-disabled={endDate == null}
                            />
                        </div>

                        {endCombinedDateTime && (
                            <span
                                style={{ fontSize: "10px" }}
                            >{`📅${endCombinedDateTime.toString()}`}</span>
                        )}
                    </div>

                </Stack>
                {EndDateErrorMessage && (
          <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
          End date is required.
        </div>
                )}
            </div>
            {liveRegionforEndDate}
            <div style={{ marginTop: "5px" }}>
                <div style={containerStyle}>
                    <Label required={isActingAsAnAdmin} style={{ marginRight: "10px", marginBottom: "3px" }}>
                        {props.intl.formatMessage(
                            messages.addNewDelegateDelegateDelegatorNotes
                        )}
                    </Label>
                    {/* This hidden label helps screen readers identify the field correctly */}
    <span id="delegator-notes-label" style={{ display: "none" }}>
        Delegator Notes
    </span>
                </div>
                <div>
                <style>
                    {`
                        .ms-TextField-fieldGroup::before {
                            content: none !important; /* Hides the asterisk */
                            display: none !important; /* Ensures it's not displayed */
                        }
                    `}
                </style>
                    <TextField
                        id="delegator-notes"
                        multiline
                        placeholder="Notes"
                        className={notesStyles.styles}
                        onChange={selectNotes}
                        value={loggedInUserNotes}
                        rows={3}
                        disabled={!selectedPartner || isCheckAdminUser}
                        aria-label="Delegator Notes"
                        aria-labelledby="delegator-notes-label"
                        aria-required={isActingAsAnAdmin} 
                        required={isActingAsAnAdmin && loggedInUserNotes === ""}
                    />

                    {delegatorNoteErrorMessage && (
                         <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
                         Delegator notes is required.
                     </div>
                    )}
                </div>
            </div>
            {/* <div style={{ marginBottom: "15px" }}></div> */}
            {AddButtonErrorMessage && (
                <div style={{ color: "#e50000" }} role="status" aria-live="assertive">
                    {AddButtonErrorMessage}
                </div>
            )}
            {showMessageBar.show && (
                <div style={{ width: "260px" }}>
                    <MessageBar
                        messageBarType={showMessageBar.messageType}
                        onDismiss={onDismissAlert}
                        isMultiline={true}
                        dismissButtonAriaLabel="Close"
                        role="status"
                        aria-live="assertive"
                    >
                        <div dangerouslySetInnerHTML={{ __html: markdown.render(showMessageBar.message)}} />
                    </MessageBar>
                </div>
            )}
            <br />
            <Stack horizontal>
                <PrimaryButton
                    disabled={disableBtn}
                    text={props.intl.formatMessage(messages.addNewDelegateDelegateAddButton)}
                    onClick={() => submitDelegation()}
                />
            </Stack>
        </div>
    );
    const renderContent = () => {
        return (
            props.IsAddPanelOpen &&
            isAddPanelOpen && (
                <Panel
                    isOpen={props.IsAddPanelOpen}
                    headerText={props.intl.formatMessage(messages.rightPanelheaderText)}
                    closeButtonAriaLabel="Close"
                    onDismiss={handlePanelDismiss}
                    type={(showSafeLimitTable || showSafeLimitTableDelegator) ? PanelType.custom : PanelType.smallFixedFar}
                    customWidth="650px"
                    styles={{
                        root: {
                            height: "100%",
                            marginTop: "50px", // Adjust the marginTop as needed
                            width: "100%",
                            // marginLeft:"100px"
                        },
                        scrollableContent: {
                            height: "90%", //90&
                        },
                    }}
                >
                    {isDelegationScope}
                </Panel>
            )
        );
    };
    const renderMain = (): JSX.Element => {
        return <Stack>{renderContent()}</Stack>;
    };
    return renderMain();
};
export const AddDelegate = injectIntl(AddDelegateComponent);