import React, { FC, useState, useContext, useRef, useEffect } from "react";
import { IDelegationDashboardProps } from "../delegationDashboard.types";
import { injectIntl } from "react-intl";
import { messages } from "../delegationDashboard.messages";
import {
  Stack,
  Panel,
  IPersonaProps,
  MessageBar,
  MessageBarType,
  IStyle,
  IPersonaSharedProps,
  IBasePickerSuggestionsProps,
  Label,
  TextField,
  PrimaryButton,
  NormalPeoplePicker,
  Dropdown,
  Checkbox,
  Icon
} from "@fluentui/react";
import {
  PanelType,
  mergeStyleSets,
  IDropdownOption,
  Spinner,
  SpinnerSize
} from "office-ui-fabric-react";
import { isMockData, mockDataMessage } from "../../utils";
import { DelegationAPI, ReassignInfo } from "../../services/delegateapi";
import { getGraphUserData } from "../../services/graphapi";
import TelemetryService from "../../services/TelemetryService/TelemetryService";
import TelemetryContext from "../../context/TelemetryContext";
import Enums from "../../services/TelemetryService/Enums";
import { mergeStyles } from "office-ui-fabric-react";
import * as MarkdownIt from 'markdown-it';

const AddReassignmentComponent: FC<
  IDelegationDashboardProps & {
    IsAddPanelOpen: boolean;
    getressigned;
    onDismiss: () => void;
  }
> = (props) => {
  const parentContext = props.parentContext;
  interface IExtendedDropdownOption extends IDropdownOption {
    id: string;
    supportedDisbursementTypeCode?: string[] | null;
    }
  const markdown = MarkdownIt().set({html: true});
  const [isReassignmentFeatureTurnOn, setFeatureTurnOn] = useState(false)
  const [selectedPersonRessaignFrom, setSelectedPersonRessaignFrom] =
    useState<IPersonaSharedProps>();
  const [selectedPersonReassignTo, setselectedPersonReassignTo] =
    useState<IPersonaSharedProps>();
  const [isAddPanelOpen, setIsAddPanelOpen] = useState(props.IsAddPanelOpen);
  const [loggedInUserNotes, setLoggedInUserNotes] = useState("");
  const [listPartnersSupported, setListPartnersSupported] = useState([]);
  const [supportedDisbursementTypeCode, setSupportedDisbursementTypeCode] =
    useState<string[]>([]);
  const [reassignToerrorMessage, setReassignToerrorMessage] = useState("");
  const [reassignToerrorMessageFlag, setReassignToerrorMessageFlag] =
    useState(false);
  const [reassignFromerrorMessageFlag, setreassignFromerrorMessageFlag] =
    useState(false);
  const [reassignFromerrorMessage, setreassignFromerrorMessage] = useState("");
  const [
    normalPeoplePickerErrorMessagereassignTo,
    setNormalPeoplePickerErrorMessagereassignTo,
  ] = useState(false);
  const [ApplicationErrorMessage, setApplicationErrorMessage] = useState(false);
  const [AddButtonErrorMessage, setAddButtonErrorMessage] = useState("");
  const [showSafeLimitTable, setShowSafeLimitTable] = useState(false);
  const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);
  const [isStartDateFocused, setisStartDateFocused] = useState(false);
  const [isEndDateFocused, setisEndDateFocused] = useState(false);
  const [isComplience, setComplience]=useState(false); 
  const [ComplientCheckBoxStyle, setComplienceCheckBoxStyle]=useState({checkbox: {background: 'white',borderColor: 'black'},text: { fontSize: '12px'}})
  const [consumerDocTypeId, setConsumerDocTypeId] = useState(null);
  const ismockdata = isMockData(parentContext);
  const [selectpartnerErrorMessage, setselectpartnerErrorMessage] =
    useState(false);
  const [reassignFromNoteErrorMessage, setreassignFromNoteErrorMessage] =
    useState(false);    
  const [loggedInUser, setLoggedInUser] = useState<IPersonaSharedProps>(null);
  const partnerOptions: IExtendedDropdownOption[] = listPartnersSupported.map(
    (partner) => ({
      key: partner.partnerName,
      id: partner.consumerDocTypeId,
      text: partner.partnerName,
      supportedDisbursementTypeCode: partner.supportedDisbursementTypeCode,
    })
  );
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [showSafeLimitTablereassignFrom, setShowSafeLimitTablereassignFrom] =
    useState(false);
  const [ressaignFromerrorMessageFlag, setRessaignFromerrorMessageFlag] =
    useState(false);
  const [ressaignFromerrorMessage, setRessaignFromerrorMessage] = useState("");
  const [
    normalPeoplePickerErrorMessageRessaignFrom,
    setNormalPeoplePickerErrorMessageRessaignFrom,
  ] = useState(false);
  const [nonExistingUser, setNonExistingUser] = useState("");
  const [isActingAsAnAdmin, setIsActingAsAnAdmin] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const { telemetryClient } = useContext(TelemetryContext);
  const [isCheckAdminUser, setIsCheckAdminUser] = useState(false);
  const [isCheckAdminUserFailError, setisCheckAdminUserFailError] = useState("");
  const chechIsuserAdmin = (
    consumerDocTypeId,
    updatedsupportedDisbursementTypeCode
  ) => {
    setDisableBtn(true);
    let api = new DelegationAPI();
    api
      .checkadminuser(parentContext, ismockdata, consumerDocTypeId)
      .then((res: any) => {
        if (res.status === 200) { 
        if (res.data.payload === null || !res.data.payload.actionResult) {
          setIsActingAsAnAdmin(false);
        } else {
          setIsActingAsAnAdmin(true);
        }
        setIsCheckAdminUser(false);
        setisCheckAdminUserFailError("");
        setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: false });
      }
      })
      .catch((e: any) => { 
        setIsCheckAdminUser(true);
        setisCheckAdminUserFailError(e.response.data);
        setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: true, message: e.response.data, messageType: MessageBarType.error })
       })
      .finally(()=>{
        setDisableBtn(false);
      });
  };
 const onComplienceChange=React.useCallback(
         (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
          setComplience(!!checked);
          var ischecked= !!checked
          if(!ischecked)
          {
            setComplienceCheckBoxStyle({checkbox: {background: '#ffffff',borderColor: '#e50000'}, text: { fontSize: '12px'}})
          }
          else
          {
            setComplienceCheckBoxStyle({checkbox: {background: 'rgb(0, 120, 212)',borderColor: '1px solid rgb(0, 120, 212)'},text: { fontSize: '12px'}})
          }
         },[],);
  const GetCurrentLoggedInUser = () => {
    const api = new DelegationAPI();
    api
      .getLoggedInUserDetail(parentContext)
      .then((res) => {
        if (res) {
          const loggedInUser: IPersonaSharedProps = {
            text: res.text,
            secondaryText: res.secondaryText,
            optionalText: res.optionalText,
          };
          setLoggedInUser(loggedInUser);
        }
      })
      .catch((error) => {});
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (dropdownRef.current) {
        dropdownRef.current.focus();
      }
      GetCurrentLoggedInUser();
    }, 200);
  }, []);

  const handlePanelDismiss = () => {
    props.onDismiss();
  };

  useEffect(() => {
    let api = new DelegationAPI();
    let logData: any = {};
    TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.DelegationInfoProcessingReassignment, props.appName, logData)

    api
      .getDelegationInfo(parentContext, ismockdata)
      .then((res: any) => {
        setListPartnersSupported(res.data.partnersSupported);
        if (res && res.data.partnersSupported) {
          for (let i = 0; i < res.data.partnersSupported.length; i++) {
            let logData: any = {};
            logData[Enums.LogDataKey.AppName] = res.data.appName;
            logData[Enums.LogDataKey.AppClientId] = res.data.appClientId;
            logData[Enums.LogDataKey.PartnerName] =
              res.data.partnersSupported[i].partnerName;
            logData[Enums.LogDataKey.ConsumerDocTypeId] =
              res.data.partnersSupported[i].consumerDocTypeId;
            logData[Enums.LogDataKey.supportedDisbursementTypeCode] =
              res.data.partnersSupported[i].supportedDisbursementTypeCode;
          }
        }
      })
      .catch((e: any) => {
        const stackTrace = e.stack || "";
        const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
        const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;
        // Extracting Exception type
        const exceptionType = e.message ? e.message.split(":")[0] : "";
        TelemetryService.trackException(
            parentContext,
            telemetryClient,
            Enums.TrackingEvent.DelegationInfoFailReassignment,
            e.response ? e.response.data : "",
            exceptionType,
            failedMethod,
            props.appName
        );
      });
  }, [
    parentContext,
    isAddPanelOpen,
    isCheckboxFocused,
    isStartDateFocused,
    isEndDateFocused,
  ]);

  const notesStyles = mergeStyleSets({
    styles: { width: 260, marginBottom: 5 },
  });
  function getTextFromItem(persona: IPersonaProps): string {
    return persona.text as string;
  }
  const onChangeSelectionRessaignFrom = (items) => {
    if (items && items.length > 0) {
      const personDetail: IPersonaSharedProps = {
        text: items[0].text,
        secondaryText: items[0].secondaryText,
        optionalText: items[0].optionalText,
      };
      setSelectedPersonRessaignFrom(personDetail);
      if (
        selectedPersonReassignTo &&
        selectedPersonReassignTo.secondaryText != undefined &&
        selectedPersonReassignTo.secondaryText != null
      ) {
        if (
          personDetail &&
          personDetail.secondaryText === selectedPersonReassignTo.secondaryText
        ) {
          setRessaignFromerrorMessage(
            "Delegate and RessaignFrom cannot be the same person"
          );
          setRessaignFromerrorMessageFlag(true);
          return;
        }
      }
      if (isActingAsAnAdmin) {
        if (items.length > 0) {
          setNormalPeoplePickerErrorMessageRessaignFrom(false);
        } else {
          setNormalPeoplePickerErrorMessageRessaignFrom(true);
        }
      }
    } else {
      setNormalPeoplePickerErrorMessageRessaignFrom(false);
      setSelectedPersonRessaignFrom(null);
      setRessaignFromerrorMessage("");
      setRessaignFromerrorMessageFlag(false);
    }
  };
  const onChangeSelectionReassignTo = (items) => {
    if (items && items.length > 0) {
      setNormalPeoplePickerErrorMessagereassignTo(false);
      const personDetail: IPersonaSharedProps = {
        text: items[0].text,
        secondaryText: items[0].secondaryText,
        optionalText: items[0].optionalText,
      };
      setselectedPersonReassignTo(personDetail);
      if (nonExistingUser != undefined && nonExistingUser != null) {
        if (personDetail && personDetail.secondaryText === nonExistingUser) {
          setReassignToerrorMessage(
            "AssignedTo and AssignedFrom cannot be the same person"
          );
          setReassignToerrorMessageFlag(true);
          return;
        }
      }
    } else {
      setNormalPeoplePickerErrorMessagereassignTo(false);
      setselectedPersonReassignTo(null);
      setReassignToerrorMessage("");
      setReassignToerrorMessageFlag(false);
      setShowSafeLimitTable(false);
    }
  };

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Suggested People",
    noResultsFoundText: "No results found",
    loadingText: "Loading...",
    showRemoveButtons: false,
    suggestionsAvailableAlertText: "People Picker Suggestions available",
    suggestionsContainerAriaLabel: "Suggested contacts",
    removeButtonIconProps: { iconName: "ProfileSearch" },
    suggestionsClassName: "",
  };

  const onAssignToFilterChanged = (
    filterText: string
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    debugger;
    if (filterText) {
      return new Promise((resolve) => {
        try {
          getGraphUserData(filterText, parentContext).then((result) => {
            resolve(
              result.value.map((item: any) => {
                return {
                  text: item.displayName,
                  secondaryText: item.userPrincipalName,
                  optionalText: item.id,
                };
              })
            );
          });
        } catch {
          resolve([]);
        }
      });
    } else {
      return [];
    }
  };

  const onAssignFromFilterChanged = (
    filterText: string
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    debugger;
    if (filterText) {
      return new Promise((resolve) => {
        try {
          getGraphUserData(filterText, parentContext).then((result) => {
            if (result.value.length == 0) {
              setNonExistingUser(filterText);
            } else {
              setNonExistingUser("");
            }
            resolve(
              result.value.map((item: any) => {
                return {
                  text: item.displayName,
                  secondaryText: item.userPrincipalName,
                  optionalText: item.id,
                };
              })
            );
          });
        } catch {
          setNonExistingUser("");
          resolve([]);
        }
      });
    } else {
      setNonExistingUser("");
      return [];
    }
  };
  //#endregion

  const selectNotes = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newText: string
  ): void => {
    setLoggedInUserNotes(newText);
    setreassignFromNoteErrorMessage(false);
  };

  const [showMessageBar, setShowMessageBar] = useState({
    show: false,
    message: "",
    messageType: MessageBarType.error,
  });

  const onDismissAlert = () => {
    setShowMessageBar({ ...showMessageBar, show: false });
  };
  const [showMessageBarIsCheckAdmin, setShowMessageBarIsCheckAdmin] = useState({
    show: false,
    message: "",
    messageType: MessageBarType.error,
});
const onDismissAlertIsCheckAdmin = () => {
    setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: false });
};

  const [disableBtn, setDisableBtn] = useState(false);
  const personaStyle = mergeStyleSets({
    persona: {
      marginTop: 10,
      marginBottom: 15,
      borderRadius: 15,
      border: "0px solid #c8c6c4",
      background: "#605e5d1c",
      height: 60,
    } as IStyle,
    rectangle: {
      position: "relative",
      padding: 5,
      width: 230,
      height: 500,
    } as IStyle,
    bText: {
      marginLeft: 5,
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 15,
    } as IStyle,
    loaderContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    } as IStyle,
    disablepointerevents: { pointerEvents: "none" } as IStyle,
  });

  const label = "Wait...";
  let submitloader = disableBtn ? (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        backgroundColor: "rgba(229, 229, 229, 0.5)",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner
        id="addNewdelegate-spinner"
        label={label}
        labelPosition="right"
        ariaLabel={"Wait for select Application."}
        size={SpinnerSize.large}
      />
    </div>
  ) : (
    ""
  );

  const ResetAllControl = () => {
    setselectedPersonReassignTo(null);
    setSelectedPersonRessaignFrom(null);
    setLoggedInUserNotes("");
    setComplience(false);
    setComplienceCheckBoxStyle({checkbox: {background: 'white',borderColor: 'black'},text: { fontSize: '12px'}})
  };
  const submitReassign = async () => {
    setNormalPeoplePickerErrorMessageRessaignFrom(false);
    let isValidation=false;
    const isValueSelected =
      selectedPartner !== null && selectedPartner !== undefined;
    if (!isValueSelected) {
      setselectpartnerErrorMessage(true);
      isValidation=true;
    }
    if(isCheckAdminUser)
      {
          setShowMessageBarIsCheckAdmin({ ...showMessageBarIsCheckAdmin, show: true, message: isCheckAdminUserFailError, messageType: MessageBarType.error });
          isValidation=true;
      }
    if (isValueSelected && !isCheckAdminUser) {
      const assignedFromRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/;
      if (isActingAsAnAdmin) {
        if (
          selectedPersonRessaignFrom !== null &&
          selectedPersonRessaignFrom !== undefined
        ) {
          if (
            selectedPersonRessaignFrom.secondaryText === null ||
            selectedPersonRessaignFrom.secondaryText === undefined
          ) {
            setNormalPeoplePickerErrorMessageRessaignFrom(true);
            isValidation=true;
          }
        } else if (!assignedFromRegex.test(nonExistingUser)) {
          setNormalPeoplePickerErrorMessageRessaignFrom(true);
          isValidation=true;
        }
      }
      if (
        selectedPersonReassignTo === null ||
        selectedPersonReassignTo === undefined
      ) {
        setNormalPeoplePickerErrorMessagereassignTo(true);
        isValidation=true;
      }
      if (reassignToerrorMessage != "") {
        setReassignToerrorMessageFlag(true);
        isValidation=true;
      }
      if (reassignFromerrorMessage != "") {
        setreassignFromerrorMessageFlag(true);
        isValidation=true;
      }
      if (isActingAsAnAdmin && loggedInUserNotes === "") {
        setreassignFromNoteErrorMessage(true);
        isValidation=true;
      }
      if(!isComplience)
      {
        setComplienceCheckBoxStyle({checkbox: {background: 'white',borderColor: 'red'}, text: { fontSize: '12px'}})
        isValidation=true;
      }
    }
    if(isValidation){
      return;
  }
    onDismissAlert();
    setDisableBtn(true);
    proceedWithReassign();
  };

  const proceedWithReassign = () => {
    const createReassignPayload: ReassignInfo = {
      assignedFrom: selectedPersonRessaignFrom
        ? {
            Id: selectedPersonRessaignFrom.optionalText,
            Alias: selectedPersonRessaignFrom.secondaryText.split("@")[0],
            Email: selectedPersonRessaignFrom.secondaryText,
            Name: selectedPersonRessaignFrom.text,
          }
        : {
            Id: null,
            Alias: nonExistingUser,
            Email: null,
            Name: null,
          },
      assignedTo: {
        Email: selectedPersonReassignTo.secondaryText,
        Id: selectedPersonReassignTo.optionalText,
        Alias: null,
        Name: null,
      },
      LoggedInUserNotes: loggedInUserNotes,
      PartnerName: selectedPartner,
      ConsumerDocTypeId: applicationId,
    };
    const api = new DelegationAPI();
    const ismockdata = isMockData(parentContext);
    if (ismockdata) {
      alert(mockDataMessage);
      setDisableBtn(false);
      props.onDismiss();
      setIsAddPanelOpen(false);
      return;
    }
    TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.createReassignedProcessing, props.appName, {});

    api
      .createReassigned(parentContext, createReassignPayload)
      .then((res) => {
        if (res.status === 200) {
          setDisableBtn(false);
          props.onDismiss();
          setIsAddPanelOpen(false);
          props.getressigned(props.parentContext);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setShowMessageBar({
            ...showMessageBar,
            show: true,
            message: error.response.data,
            messageType: MessageBarType.error,
          });
          setDisableBtn(false);
          const stackTrace = error.stack || "";
          const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
          const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;
          const exceptionType = error.message ? error.message.split(":")[0] : "";
          TelemetryService.trackException(parentContext, telemetryClient, Enums.TrackingEvent.CreateReassignedFail, error.response ? error.response.data : "", exceptionType, failedMethod, props.appName);
        }
      });
  };
  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };
    const infoIconStyle = mergeStyles({
        fontSize: '18px', // Medium-sized icon
        marginRight: '8px',
        color: '#000000', // Black color for the icon
        display: 'flex',
    });
  const isReassignScope = (
    <div className={personaStyle.rectangle}>
      {submitloader}
      <div
            style={{backgroundColor: '#dbeeff', // Matching background color from the image
              padding: '6px 12px', // Medium padding for balanced height
              border: '1px solid #c2d5f2',
              display: 'flex',
              verticalAlign: 'top',
              borderRadius: '3px',
              marginTop:'10px',
            width:260}}
            role="region" // Defines a region for the screen reader
            aria-labelledby="delegation-info-heading" // Associates with the heading
          >
            <Icon
              iconName="Info"
              className={infoIconStyle}
              aria-hidden="true" // This is for decorative purposes, so hide it from screen readers
            />
            <div id="delegation-info-heading" aria-live="assertive" role="alert" style={{
                margin: 0,
                fontSize: '14px', // Medium text size
                fontWeight: 'normal',
                color: '#333',
                lineHeight: '1.2', // Slightly increased line height for better readability
              }}>
              This feature is not currently available for Compass and other applications.
            </div>
      </div>
      <div style={containerStyle}>
        <div style={{ marginTop: "5px" }}>
          <div style={{ color: "#9d6500", fontWeight: 500 , backgroundColor:"#ffffff"}} role="alert"
      aria-live="assertive" aria-atomic="true">
            {(selectedPartner !== null && !isCheckAdminUser) && (
              (isActingAsAnAdmin
                ? "You are acting as an Admin"
                : "You don't have an access to create reassignment."))}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "5px",marginBottom: "3px"  }}>
            {showMessageBarIsCheckAdmin.show && (
                <div style={{ width: "260px" }}>
                    <MessageBar
                        messageBarType={showMessageBarIsCheckAdmin.messageType}
                        onDismiss={onDismissAlertIsCheckAdmin}
                        isMultiline={true}
                        dismissButtonAriaLabel="Close"
                        role="status"
                        aria-live="assertive"
                    >
                        <div dangerouslySetInnerHTML={{__html: markdown.render(showMessageBarIsCheckAdmin.message)}} />
                    </MessageBar>
                </div>
            )}
            </div>
      <div style={{ marginTop: "5px" }}>
        <div style={containerStyle}>
          <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
            {props.intl.formatMessage(messages.reassignmentApplication)}
          </Label>
        </div>
        <style>
        {`
          .ms-Dropdown.is-required::before {
            content: none !important; /* Removes the required asterisk */
          }
        `}
      </style>
        <Dropdown
          componentRef={dropdownRef}
          placeholder="Select an Application"
          options={partnerOptions}
          selectedKey={selectedPartner}
          onChange={(e, option: IExtendedDropdownOption) => {
            setSelectedPartner(option.key.toString());
            setConsumerDocTypeId(option.id);
            setApplicationId(option.id);
            setselectpartnerErrorMessage(false);
            var updatedsupportedDisbursementTypeCode =
              option.supportedDisbursementTypeCode;
            setSupportedDisbursementTypeCode(
              updatedsupportedDisbursementTypeCode ?? []
            );
            chechIsuserAdmin(option.id, updatedsupportedDisbursementTypeCode);
            ResetAllControl();
          }}
          styles={{ dropdown: { width: 260 } ,dropdownOptionText:{whiteSpace: 'normal'}, title:{whiteSpace: 'normal'}}}
          ariaLabel="Application"
          aria-required="true"
          disabled={!isReassignmentFeatureTurnOn}
          required={!selectedPartner}
        />
        {selectpartnerErrorMessage && (
          <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
            Please select an Application
          </div>
        )}
      </div>
      <div style={{ marginTop: "5px" }}>
        <div style={containerStyle}>
          <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
            {props.intl.formatMessage(messages.reAssignFrom)}
          </Label>
        </div>
        <NormalPeoplePicker
          onResolveSuggestions={onAssignFromFilterChanged}
          onChange={(event) => {
            onChangeSelectionRessaignFrom(event);
          }}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={suggestionProps}
          className={"ms-PeoplePicker"}
          key={"normal"}
          selectionAriaLabel={"Selected contacts"}
          removeButtonAriaLabel={
            selectedPersonRessaignFrom && selectedPersonRessaignFrom.text
                ? `Remove ${selectedPersonRessaignFrom.text}`
                : "Remove selected RessaignFrom"
        } 
          inputProps={{
            "aria-label": "Assigned From",
            placeholder: "Enter Alias",
            "aria-required": (!selectedPartner || !isActingAsAnAdmin || isCheckAdminUser) ? "false" : "true",
          }}
          resolveDelay={300}
          itemLimit={1}
          selectedItems={
            !selectedPartner
              ? []
              : selectedPersonRessaignFrom
              ? [selectedPersonRessaignFrom]
              : []
          }
          disabled={!selectedPartner || !isActingAsAnAdmin|| isCheckAdminUser}
          styles={{ root: { width: 260 } }}
        />
        <div aria-live="assertive" aria-atomic="true">
          {reassignFromerrorMessageFlag && (
            <div className="error" style={{ color: "red" }}>
              {reassignFromerrorMessage}
            </div>
          )}
          {normalPeoplePickerErrorMessageRessaignFrom && isActingAsAnAdmin && (
            <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
              Please select a reassign from.
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: "5px" }}>
        <div style={containerStyle}>
          <Label required style={{ marginRight: "10px", marginBottom: "3px" }}>
            {props.intl.formatMessage(messages.reAssignTo)}
          </Label>
        </div>
        <NormalPeoplePicker
          onResolveSuggestions={onAssignToFilterChanged}
          onChange={(event) => onChangeSelectionReassignTo(event)}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={suggestionProps}
          className={"ms-PeoplePicker"}
          key={"delegate"}
          selectionAriaLabel={"Selected contacts"}
          removeButtonAriaLabel={
            selectedPersonReassignTo && selectedPersonReassignTo.text
                ? `Remove ${selectedPersonReassignTo.text}`
                : "Remove selected ReassignTo"
        } 
          inputProps={{
            "aria-label": "Assigned To",
            placeholder: "Enter Alias",
            "aria-required": (!selectedPartner || !isActingAsAnAdmin || isCheckAdminUser) ? "false" : "true",
          }}
          resolveDelay={300}
          itemLimit={1}
          selectedItems={
            selectedPersonReassignTo ? [selectedPersonReassignTo] : []
          } //
          disabled={!selectedPartner || !isActingAsAnAdmin || isCheckAdminUser}
          styles={{ root: { width: 260 } }}
        />

        <div aria-live="assertive" aria-atomic="true">
          {reassignToerrorMessageFlag && (
            <div className="error" style={{ color: "red" }}>
              {reassignToerrorMessage}
            </div>
          )}
          {normalPeoplePickerErrorMessagereassignTo && (
            <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
              Please select a reassign to.
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <div style={containerStyle}>
          <Label
            htmlFor="noteReassignment"
            required={isActingAsAnAdmin}
            style={{ marginRight: "10px", marginBottom: "3px" }}
          >
            {props.intl.formatMessage(messages.noteReassignment)}
          </Label>
          <span id="notes-label" style={{ display: "none" }}>
          Notes
    </span>
        </div>
        <div>
        <style>
                    {`
                        .ms-TextField-fieldGroup::before {
                            content: none !important; /* Hides the asterisk */
                            display: none !important; /* Ensures it's not displayed */
                        }
                    `}
                </style>
          <TextField
          id="noteReassignment"
            multiline
            placeholder="Notes"
            className={notesStyles.styles}
            onChange={selectNotes}
            value={loggedInUserNotes}
            rows={3}
            disabled={!selectedPartner || !isActingAsAnAdmin || isCheckAdminUser}
            aria-label="Notes"
            aria-labelledby="notes-label"
            aria-required={isActingAsAnAdmin} 
            required={isActingAsAnAdmin && loggedInUserNotes === ""}
            aria-describedby={reassignFromNoteErrorMessage ? "noteError" : undefined} // Associate error message with input
          />
          {reassignFromNoteErrorMessage && (
            <div style={{ color: "#e50000" }} aria-live="assertive" role="alert">
              Notes is required.
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <div style={containerStyle}>
        <Checkbox styles={ComplientCheckBoxStyle} checked={isComplience} disabled={!selectedPartner || !isActingAsAnAdmin}
         onChange={onComplienceChange}  
         label='I acknowledge that pending requests will be transferred from the "Assigned From" queue and placed into the "Assigned To" queue' 
         aria-label="Compliance acknowledgment"
         aria-required={(!selectedPartner || !isActingAsAnAdmin) ? "false" : "true"} />
        </div>
      </div>
      {AddButtonErrorMessage && (
        <div style={{ color: "#e50000" }} role="status" aria-live="assertive">
          {AddButtonErrorMessage}
        </div>
      )}
      {showMessageBar.show && (
        <div style={{ width: "260px" }}>
          <MessageBar
            messageBarType={showMessageBar.messageType}
            onDismiss={onDismissAlert}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
            role="status"
            aria-live="assertive"
          >
            <div dangerouslySetInnerHTML={{__html: markdown.render(showMessageBar.message)}}/>
          </MessageBar>
        </div>
      )}
      <br />
      <Stack horizontal>
        <PrimaryButton
          text={props.intl.formatMessage(messages.reassignmentAddButton)}
          disabled={disableBtn || (selectedPartner && !isActingAsAnAdmin) || !isReassignmentFeatureTurnOn}
          onClick={() => submitReassign()}
          aria-label={disableBtn ? "Add Reassignment button is disabled" : "Add Reassignment"} // Clear description for screen readers
          aria-disabled={disableBtn || (selectedPartner && !isActingAsAnAdmin) ? "true" : "false"} // Indicates button state
        />
      </Stack>
    </div>
  );
  const renderContent = () => {
    return (
      props.IsAddPanelOpen &&
      isAddPanelOpen && (
        <Panel
          isOpen={props.IsAddPanelOpen}
          headerText={props.intl.formatMessage(
            messages.rightPanelReassignmentheaderText
          )}
          closeButtonAriaLabel="Close"
          onDismiss={handlePanelDismiss}
          type={
            showSafeLimitTable || showSafeLimitTablereassignFrom
              ? PanelType.custom
              : PanelType.smallFixedFar
          }
          customWidth="849px"
          styles={{
            root: {
              height: "100%",
              marginTop: "50px",
              width: "100%",
            },
            scrollableContent: {
              height: "90%",
            },
          }}
        >
          {isReassignScope}
        </Panel>
      )
    );
  };
  const renderMain = (): JSX.Element => {
    return <Stack>{renderContent()}</Stack>;
  };
  return renderMain();
};
export const AddReassignment = injectIntl(AddReassignmentComponent);
