import React from 'react';
import { IAppContext } from '@msx/platform-services';
import { DataService } from '../services';
import { DataServiceContext } from '../context'
import { IDataServiceContainer } from '../interfaces';

type OwnProps = {
  parentContext: IAppContext
}
export const AppDataServiceProvider: (React.FC<OwnProps>) = props => {
  const { parentContext } = props;
  const httpClient = parentContext ? parentContext.getHttpClient() : null;
  const dataService = new DataService(httpClient);
  const dataServiceContainer: IDataServiceContainer = {
    dataService: dataService,
  }
  return (
    <DataServiceContext.Provider value={dataServiceContainer}>
      {props.children}
    </DataServiceContext.Provider>);
}
