import axios, { AxiosRequestConfig } from "axios";
import { getAccessToken } from "./authprovider";
import { IAppContext, IAppTheme } from '@msx/platform-services';
import { IPersonaSharedProps } from '@fluentui/react';
import { Guid } from "../utils/utils";
import jwt from "jsonwebtoken";

const baseUrl = process.env.REACT_APP_WEB_API_ENDPOINT;

export const msalApiGetAsync = async (url: string, parentContext: IAppContext): Promise<any> => {
    const token = `${"Bearer "}${await getAccessToken(parentContext)}`;
    const requestConfig: AxiosRequestConfig = {
        headers: { 'Authorization': token, "Xcv":Guid.newGuid(),"Tcv":Guid.newGuid() },
        method: 'get'
    }
    return await axios.get(url, requestConfig);
};

export const msalApiPostAsync = async (route: string, parentContext: IAppContext, body: string): Promise<any> => {
    const token = `${"Bearer "}${await getAccessToken(parentContext)}`;   
    const requestConfig: AxiosRequestConfig = {
        headers: { 'Authorization': token, "Xcv":Guid.newGuid(),"Tcv":Guid.newGuid()},
        method: 'post'
    } 
    const url = `${baseUrl}${route}`;
    const data = await axios.post(url, JSON.parse(body), requestConfig);
    
    return data;
};

export const msalApiPutAsync = async (route: string,parentContext: IAppContext,delegationId: string,body: string): Promise<any> => {
    const token = `${"Bearer "}${await getAccessToken(parentContext)}`;
    const requestConfig: AxiosRequestConfig = {
        headers: { 'Authorization': token, "Xcv":delegationId,"Tcv":Guid.newGuid()},
        method: 'put'
    }
    const url = `${baseUrl}${route}`;
    const data = await axios.put(url, JSON.parse(body), requestConfig);;
    return data;
};

export const msalApiPatchAsync = async (route: string ,parentContext: IAppContext,delegationId: string): Promise<any> => {
    const token = `${"Bearer "}${await getAccessToken(parentContext)}`;
    const requestConfig: AxiosRequestConfig = {
        headers: { 'Authorization': token, "Xcv":delegationId,"Tcv":Guid.newGuid()},
        method: 'put'
    }
    const url = `${baseUrl}${route}`;
    const data = await axios.put(url,requestConfig);
    return data;
};

export const msalLoggedInUser=async (parentContext: IAppContext): Promise<any> => {
    const token = `${"Bearer "}${await getAccessToken(parentContext)}`;
const tokenWithoutPrefix = token.replace('Bearer ', '');
const decodedToken = jwt.decode(tokenWithoutPrefix);
const preferredUsername = decodedToken.preferred_username;
return preferredUsername;
}

export const msalLoggedInUserName=async (parentContext: IAppContext): Promise<any> => {
    const token = `${"Bearer "}${await getAccessToken(parentContext)}`;
const tokenWithoutPrefix = token.replace('Bearer ', '');
const decodedToken = jwt.decode(tokenWithoutPrefix);
const name = decodedToken.name;
return name;
}

export const msalLoggedInUserDetail = async (parentContext: IAppContext): Promise<IPersonaSharedProps> => {
    const token = `${"Bearer "}${await getAccessToken(parentContext)}`;
    const tokenWithoutPrefix = token.replace('Bearer ', '');
    const decodedToken = jwt.decode(tokenWithoutPrefix) as any;

    const personDetail: IPersonaSharedProps = {
        text: decodedToken.name, // Assuming 'name' is the primary text
        secondaryText: decodedToken.preferred_username, // Assuming 'preferred_username' is the secondary text
        optionalText: decodedToken.oid // Assuming 'email' is the optional text, update according to your token structure
    };

    return personDetail;
}