// TelemetryContext.js
import { useState, createContext, useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const TelemetryContext = createContext({
  telemetryClient: null,
  initializeTelemetryClient: (instrumentationKey) => {},
  customLogs: (
    delegate,
    applicationName,
    permission,
    startDate,
    endDate,
    notes
  ) => {},
});

export const TelemetryContextProvider = ({ children }) => {
  const [telemetryClient, setTelemetryClient] = useState(null);

  function initializeTelemetryClient(instrumentationKey) {
    if (telemetryClient === null) {
      let appInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          disableFetchTracking: false,
        },
      });
      appInsights.loadAppInsights();
      setTelemetryClient(appInsights);
    }
  }

  useEffect(() => {
    // Initialize telemetryClient when the component mounts
    initializeTelemetryClient(process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY);
  }, []);

  const customLogs = (
    delegate,
    applicationName,
    permission,
    startDate,
    endDate,
    notes
  ) => {
    if (telemetryClient) {
      telemetryClient.trackEvent({
        name: "CustomUILogs",
        properties: {
          eventType: "Success",
          delegate,
          applicationName,
          permission,
          startDate,
          endDate,
          notes,
        },
      });
    }
  };

  return (
    <TelemetryContext.Provider
      value={{ telemetryClient, initializeTelemetryClient, customLogs }}
    >
      {children}
    </TelemetryContext.Provider>
  );
};

export default TelemetryContext;