import { IHttpClient, IHttpClientRequest } from '@msx/platform-services';
import { IDataService, IToDo } from '../interfaces';

const DefaultHttpClientRequest: Partial<IHttpClientRequest> = {
  responseType: 'json',
  header: {
    'Access-Control-Allow-Origin': '*'
  },
  resource: process.env.REACT_APP_WEB_API_RESOURCE_ID
};

export class DataService implements IDataService {
  private httpClient: IHttpClient;
  private baseUrl: string;

  public constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
    this.baseUrl = process.env.REACT_APP_WEB_API_ENDPOINT;
  }

  public async GetAllToDo(): Promise<[IToDo]> {
    const url = `${this.baseUrl}TodoList`;

    return new Promise((resolve, reject): void => {
      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        method: 'GET',
        url: url
      };
      this.httpClient
        .request<[IToDo]>(request)
        .then((response): void => {
          resolve(response.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }


  public async AddToDo(todo: IToDo): Promise<IToDo> {
    const url = `${this.baseUrl}TodoList`;

    return new Promise((resolve, reject): void => {
      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        method: 'POST',
        url: url,
        data: todo
      };
      this.httpClient
        .request<IToDo>(request)
        .then((response): void => {
          resolve(response.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }

  public async UpdateToDo(todo: IToDo): Promise<IToDo> {
    const url = `${this.baseUrl}TodoList/${todo.id}`;

    return new Promise((resolve, reject): void => {
      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        method: 'PATCH',
        url: url,
        data: todo
      };
      this.httpClient
        .request<IToDo>(request)
        .then((response): void => {
          resolve(response.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }

  public async DeleteToDo(todo: IToDo): Promise<IToDo> {
    const url = `${this.baseUrl}TodoList/${todo.id}`;

    return new Promise((resolve, reject): void => {
      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        method: 'DELETE',
        url: url,
        data: todo
      };
      this.httpClient
        .request<IToDo>(request)
        .then((response): void => {
          resolve(response.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }
}