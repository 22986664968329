import { IAppContext, IAppTheme } from '@msx/platform-services';

export async function getAccessToken(parentContext: IAppContext) {
    // -----------------------------------------------------------
    // This example is illustraded how to get a token for a given API resource ID
    // -----------------------------------------------------------
    // Web UI app registration name   : PBIEmbed
    // Web UI App client ID           : 083abf93-4780-4daf-98da-8429b80872fb
    // -----------------------------------------------------------
    // Web API app registration name  : MonitoringService
    // Web API App client ID          : 7d8cedcc-ca83-4144-9a3c-5a7238e645df
    // -----------------------------------------------------------
    try {
        const resource =  process.env.REACT_APP_WEB_API_RESOURCE_ID;
        const authContext = parentContext.getAuthContext();
        const token = await authContext.getToken(resource);
        return token;
    }
    catch (ex) {
      throw ex
    }
}

export async function graphApiToken(parentContext: IAppContext) {
    // -----------------------------------------------------------
    // This example is illustraded how to get a graph token
    // -----------------------------------------------------------
    // Web UI app registration name   : PBIEmbed
    // Web UI App client ID           : 083abf93-4780-4daf-98da-8429b80872fb
    // -----------------------------------------------------------
    // Web API app registration name  : MonitoringService
    // Web API App client ID          : 7d8cedcc-ca83-4144-9a3c-5a7238e645df
    // -----------------------------------------------------------
    try {
      const resource = 'https://graph.microsoft.com/User.ReadBasic.All';
      const authContext = parentContext.getAuthContext();
      const token = await authContext.getToken(resource);
      return token;
    }
    catch (ex) {
      throw ex
    }
  }