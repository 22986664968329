import React, { FC, useContext, useEffect, useState } from "react";
import { IDelegationDashboardProps } from "../delegationDashboard.types";
import { injectIntl } from "react-intl";
import { messages } from "../delegationDashboard.messages";
import {
  IButtonStyles,
  IStyle,
  mergeStyleSets,
  Stack,
  DetailsList,
  SelectionMode,
  Spinner,
  SpinnerSize,
  TooltipHost,
  Icon,
  TooltipDelay,
  DirectionalHint,
  DetailsListLayoutMode
} from "office-ui-fabric-react";
import { DefaultButton } from "@fluentui/react";
import { AddReassignment } from "./addReassignment";
import { isMockData } from "../../utils";
import { DelegationAPI } from "../../services/delegateapi";
import TelemetryService from "../../services/TelemetryService/TelemetryService";
import TelemetryContext from '../../context/TelemetryContext'
import Enums from "../../services/TelemetryService/Enums";
import { EmptyResults } from "../shared/EmptyResult";

const infoIconClass=mergeStyleSets({
  infoIcon :{cursor: 'pointer', verticalAlign:'middle'} as IStyle
})
const ReassignmentComponent: FC<
  IDelegationDashboardProps & { updateRecordCount: (count: number) => void }
> = (props) => {
  const { parentContext, updateRecordCount } = props;
  const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [flag, setFlag] = React.useState(true);
  const [ariaLabel, setAriaLabel] = useState("Reassignmet Data Loading");
  const [reassignedRecords, setreassignedRecords] = useState([]);
  const [isSortedDescending, setIsSortedDescending] = useState(false);  // Sort order (ascending/descending)
  const [sortedColumn, setSortedColumn] = useState(null);  // Currently sorted column
  const { telemetryClient } = useContext(TelemetryContext);
  const personaStyle = mergeStyleSets({
    iconButton: { marginTop: 15 } as IStyle,
  });
  const handleAddPanelDismiss = () => {
    setIsAddPanelOpen(false);
  };
  const label = flag ? "Loading" : "Wait...";
  let submitloader = isPageLoading ? (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        backgroundColor: "rgba(229, 229, 229, 0.5)",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner
        id="reassignment-spinner"
        label={label}
        labelPosition="right"
        ariaLabel={ariaLabel}
        size={SpinnerSize.large}
        role="status" 
      />
    </div>
  ) : (
    ""
  );

  const ReassignButtonStyles: IButtonStyles = {
    root: {
      marginTop: 4,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      cursor: "pointer",
      border: "none",
      background: "none",
      padding: 0,
    },
    icon: {
      fontSize: "16px",
      color: "rgb(0, 108, 190)",
    },
    label: {
      color: "rgb(0, 108, 190)",
      cursor: "pointer",
    },
    rootHovered: {
      background: "none !important",
    },
  };

  const handleClick = () => {
    setIsAddPanelOpen(true);
  };
  useEffect(() => {
    if (parentContext) {
      let api = new DelegationAPI();
      let logData: any = {};
      TelemetryService.trackEvent(parentContext,telemetryClient,Enums.TrackingEvent.GetreassignmentProcessing,props.appName,logData)
      const ismockdata = isMockData(parentContext);
      api
        .getressigned(parentContext, ismockdata)
        .then((res: any) => {
          if (res.data && res.data !== "") {
            const reassignedRecords = res.data;            
            setreassignedRecords(reassignedRecords);
          }
        })
        .catch((e: any) => {
          const stackTrace = e.stack || "";
          const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
          const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

          // Extracting Exception type
          const exceptionType = e.message ? e.message.split(":")[0] : "";
          TelemetryService.trackException(
              parentContext,
              telemetryClient,
              Enums.TrackingEvent.GetreassignmentFail,
              e.response ? e.response.data : "",
              exceptionType,
              failedMethod,
              props.appName
          );
        })
        .finally(() => {
          setIsPageLoading(false);
        });
    }
    setAriaLabel(
      label === "Loading" ? "Reassignmet Data Loading" : "Waiting for data..."
    );
  }, [parentContext]);

  const getressignedCall = (parentContext) => {
    if (parentContext) {
      const ismockdata = isMockData(parentContext);
      let api = new DelegationAPI();
      api
        .getressigned(parentContext, ismockdata)
        .then((res: any) => {
          if (res.data && res.data !== "") {
            const reassignedRecords = res.data;
            const count = reassignedRecords.length;
            setreassignedRecords(reassignedRecords);
            updateRecordCount(count);
          }
        })
        .catch((e: any) => {});
    }
  };
  const onColumnClick = (columnName) => {
    const isCurrentlySortedDescending = sortedColumn === columnName && isSortedDescending;
    setSortedColumn(columnName);
    setIsSortedDescending(!isCurrentlySortedDescending);
    // Sort the records based on the clicked column
    const sortedRecords = [...reassignedRecords].sort((a, b) => {
      if (columnName === "application") {
          const aValue = a.partnerName.toLowerCase();
          const bValue = b.partnerName.toLowerCase();
 
          return isCurrentlySortedDescending
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue);
      } else if (columnName === "assignedFrom") {
          // Extract first and last names for assignedFrom
          const [aFirstName, aLastName] = a.assignedFrom.name !== null
              ? a.assignedFrom.name.split(" ")
              : [a.assignedFrom.alias, ""];
          const [bFirstName, bLastName] = b.assignedFrom.name !== null
              ? b.assignedFrom.name.split(" ")
              : [b.assignedFrom.alias, ""];
         
          // Combine first and last names for sorting
          const aFullName = `${aFirstName.toLowerCase()} ${aLastName.toLowerCase()}`;
          const bFullName = `${bFirstName.toLowerCase()} ${bLastName.toLowerCase()}`;
 
          return isCurrentlySortedDescending
              ? bFullName.localeCompare(aFullName)
              : aFullName.localeCompare(bFullName);
      } else if (columnName === "assignedTo") {
          // Extract first and last names for assignedTo
          const [aFirstName, aLastName] = a.assignedTo.name !== null
              ? a.assignedTo.name.split(" ")
              : ["", ""];
          const [bFirstName, bLastName] = b.assignedTo.name !== null
              ? b.assignedTo.name.split(" ")
              : ["", ""];
         
          // Combine first and last names for sorting
          const aFullName = `${aFirstName.toLowerCase()} ${aLastName.toLowerCase()}`;
          const bFullName = `${bFirstName.toLowerCase()} ${bLastName.toLowerCase()}`;
 
          return isCurrentlySortedDescending
              ? bFullName.localeCompare(aFullName)
              : aFullName.localeCompare(bFullName);
      } else if (columnName === "CreatedBy") {
          const aValue = a.loggedInUser.name.toLowerCase();
          const bValue = b.loggedInUser.name.toLowerCase();
 
          return isCurrentlySortedDescending
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue);
      } else if (columnName === "CreatedOn") {
          const aDate = new Date(a.createdDate).getTime();
          const bDate = new Date(b.createdDate).getTime();
 
          return isCurrentlySortedDescending ? bDate - aDate : aDate - bDate;
      } else if (columnName === "notes") {
          const aValue = a.loggedInUserNotes.toLowerCase();
          const bValue = b.loggedInUserNotes.toLowerCase();
 
          return isCurrentlySortedDescending
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue);
      } else {
          // Handle sorting for other columns if needed
          return 0; // Default return statement for other columns
      }
  });
 
  setreassignedRecords(sortedRecords);
 
};
  const reassignmentsColumn = [
    {
      key: "application",
      name: "Application",
      fieldName: "application",
      isResizable: true,
      minWidth: 100,
      maxWidth: 350,
      isSorted: sortedColumn === "application",
      isSortedDescending: isSortedDescending,
      onColumnClick: () => onColumnClick("application"),
      ariaSort: sortedColumn === "application" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `application column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
      key: "assignedFrom",
      name: "Assigned From",
      fieldName: "assignedFrom",
      isResizable: true,
      minWidth: 120,
      maxWidth: 210,
      isSorted: sortedColumn === "assignedFrom",
      isSortedDescending: isSortedDescending,
      onColumnClick: () => onColumnClick("assignedFrom"),
      ariaSort: sortedColumn === "assignedFrom" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `assignedFrom column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
      key: "assignedTo",
      name: "Assigned To",
      fieldName: "assignedTo",
      isResizable: true,
      minWidth: 120,
      maxWidth: 210,
      isSorted: sortedColumn === "assignedTo",
      isSortedDescending: isSortedDescending,
      onColumnClick: () => onColumnClick("assignedTo"),
      ariaSort: sortedColumn === "assignedTo" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `assignedTo column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
      key: "CreatedBy",
      name: "Created by",
      fieldName: "CreatedBy",
      isResizable: true,
      minWidth: 120,
      maxWidth: 210,
      isSorted: sortedColumn === "CreatedBy",
      isSortedDescending: isSortedDescending,
      onColumnClick: () => onColumnClick("CreatedBy"),
      ariaSort: sortedColumn === "CreatedBy" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `CreatedBy column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
      key: "CreatedOn",
      name: "Created On",
      fieldName: "CreatedOn",
      isResizable: true,
      minWidth: 180,
      maxWidth: 210,
      isSorted: sortedColumn === "CreatedOn",
      isSortedDescending: isSortedDescending,
      onColumnClick: () => onColumnClick("CreatedOn"),
      ariaSort: sortedColumn === "CreatedOn" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `CreatedOn column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
      key: "note",
      name: "Note",
      fieldName: "notes",
      isResizable: true,
      minWidth: 120,
      maxWidth: 150,
      isSorted: sortedColumn === "notes",
      isSortedDescending: isSortedDescending,
      onColumnClick: () => onColumnClick("notes"),
      ariaSort: sortedColumn === "notes" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `notes column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
  ];
  const formatDate = (date) => {
    return new Date(date).toLocaleString([], {
        year: "numeric",
        month: "short",
        day: "2-digit", 
        hour: "2-digit",
        minute: "2-digit"
    });
};

const ReassignmentsHeader=<div><div style={{display:'inline-block'}}><h2 style={{ fontSize: '18px', marginTop: '10px',marginBottom:'0px' }}>
                                                                      Your Reassignments

                                                                    </h2>
                                </div>
                                <div style={{display:'inline', fontSize:'18px'}}>
                                        <TooltipHost
                                                content="This page shows your reassignments. Administrators can reassign on behalf of users"
                                                delay={TooltipDelay.zero}
                                                directionalHint={DirectionalHint.topCenter}
                                                id="tooltip-yourReassignments"
                                              >
                                                <span
                                                  role="tooltip" 
                                                  tabIndex={0} // Makes the icon focusable with the keyboard
                                                  aria-label="Your Reassignments" // Screen readers will announce this
                                                  aria-labelledby="tooltip-yourReassignments"
                                                  onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                      e.preventDefault();

                                                      // Cast e.target to HTMLElement
                                                      const targetElement = e.target as HTMLElement;

                                                      if (targetElement.click) {
                                                        targetElement.click(); // Now TypeScript will not complain
                                                      }
                                                    }
                                                  }}
                                                  // Add margin to create space between text and icon
                                                  style={{ marginLeft: '8px' }} // Adjust the value as needed
                                                >
                                                  <Icon iconName="Info" className={infoIconClass.infoIcon} />
                                                </span>
                                              </TooltipHost>
                                </div>
                              </div>
  const renderContent = () => {
    
if (isPageLoading) {
  return submitloader;
}
    return (
      <div className={personaStyle.iconButton}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <DefaultButton
            text={props.intl.formatMessage(
              messages.rightPanelReassignmentheaderText
            )}
            iconProps={{ iconName: "Assign" }}
            styles={ReassignButtonStyles}
            onClick={handleClick}
            aria-label="Add New Reassignment" 
            role="button"
          />
        </Stack>

        {isAddPanelOpen && (
          <AddReassignment
            parentContext={parentContext}
            IsAddPanelOpen={true}
            onDismiss={handleAddPanelDismiss}
            appName={props.appName}
            updateRecordCount={updateRecordCount}
            getressigned={getressignedCall}
          />
        )}
        <Stack
          tokens={{ childrenGap: 5 }}
          styles={{ root: { paddingTop: 5 } }}
        >
          <div>
            {ReassignmentsHeader}
            {reassignedRecords.length > 0 ? (
              <DetailsList
                items={reassignedRecords.map(
                  ({
                    id,
                    assignedFrom,
                    assignedTo,
                    partnerName,
                    createdDate,
                    loggedInUser,
                    loggedInUserNotes,
                  }) => {
                    const [assignedFromfirstName, assignedFromlastName] =
                      assignedFrom.name !== null
                        ? assignedFrom.name.split(" ")
                        : [assignedFrom.alias, ""];
                    const [assignedTofirstName, assignedTolastName] =
                      assignedTo.name !== null
                        ? assignedTo.name.split(" ")
                        : ["", ""];
                    const assignedTousername = assignedTo.email
                      ? assignedTo.email.split("@")[0]
                      : "";
                    const [loggedInUserfirstName, loggedInUserlastName] = loggedInUser.name ? loggedInUser.name.split(" ") : "";
                    const loggedInUserAlias = loggedInUser.email? loggedInUser.email.split("@")[0]: "";
                    const assignedFromAlias = assignedFrom.email? assignedFrom.email.split("@")[0]: "";
                    return {
                      id,
                      assignedFrom: `${assignedFromfirstName} ${assignedFromlastName}`,
                      assignedTo: `${assignedTofirstName} ${assignedTolastName}`,
                      application: partnerName,
                      notes: loggedInUserNotes,
                      assignedTousername: assignedTousername,
                      CreatedOn : formatDate(createdDate),
                      loggedInUserAlias:loggedInUserAlias,  
                      assignedFromAlias:assignedFromAlias,                               
                      CreatedBy:`${loggedInUserfirstName} ${loggedInUserlastName}`
                    };
                  }
                )}
                columns={reassignmentsColumn}
                compact={true}
                styles={{
                    contentWrapper: {
                      maxHeight: window.innerHeight-400,
                      height:'auto',
                      overflowX:'hidden'
                    }
                  }}
                selectionMode={SelectionMode.none}
                ariaLabelForListHeader="Reassignment records"
                ariaLabel="Reassignment Details List"
                role="grid"
                onRenderItemColumn={(item, index, column) => {
                  if (column.key === "assignedFrom") {
                    return (
                      <TooltipHost content={item.assignedFromAlias}>
                        <div id={`row_${item.id}`}  style={{ cursor: 'pointer' }}>
                          {item[column.fieldName]}
                        </div>
                      </TooltipHost>
                    );
                  }
                  if (column.key === "assignedTo") {
                    return (
                      <TooltipHost content={item.assignedTousername}>
                        <div id={`row_${item.id}`} style={{ cursor: 'pointer' }}>
                          {item[column.fieldName]}
                        </div>
                      </TooltipHost>
                    );
                  }
                  if (column.key === "CreatedBy") {
                    return (
                      <TooltipHost content={item.loggedInUserAlias}>
                        <div id={`row_${item.id}`} style={{ cursor: 'pointer' }}>
                          {item[column.fieldName]}
                        </div>
                      </TooltipHost>
                    );
                  }
                  return item[column.fieldName];
                }}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                <EmptyResults message="You have no reassignments" />
              </div>
            )}
          </div>
        </Stack>
      </div>
    );
  };

  const renderMain = (): JSX.Element => {
    return <Stack>{renderContent()}</Stack>;
  };
  return renderMain();
};
export const Reassignments = injectIntl(ReassignmentComponent);