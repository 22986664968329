import moment from 'moment';
import { IAppContext, IAppTheme } from '@msx/platform-services'
import { DefaultTheme } from '@msx/react-ui-component';
import { InjectedIntlProps } from 'react-intl';

export const mockDataMessage = "The app is currently using mock data, so any additions, modifications or deletion to the records will not be reflected on the screen."

const minDate = "01/01/0001";

export class GlobalStorage {
  private static instance: GlobalStorage;
  private variables: { [key: string]: any } = {};

  private constructor() {
    // Private constructor to prevent external instantiation.
  }

  static getInstance(): GlobalStorage {
    if (!GlobalStorage.instance) {
      GlobalStorage.instance = new GlobalStorage();
    }
    return GlobalStorage.instance;
  }

  set(key: string, value: any): void {
    this.variables[key] = value;
  }

  get(key: string): any {
    return this.variables[key];
  }
}



export class Guid {
  private static s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  static newGuid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }
}

export function isEmpty(objectToCheck) {
  let result = true;
  if (!objectToCheck) return result;
  if (objectToCheck.constructor === Object) {
    result = Object.entries(objectToCheck).length === 0
  }
  if (Array.isArray(objectToCheck)) {
    result = objectToCheck.length === 0;
  }
  return result;
}

export const maskstring = (str: string) => {
  if (!str || str === '--' || str.length === 0) return str;
  let last = str;
  if (str.length > 4)
    last = str.substring(str.length - 4);
  return "*".repeat(6) + last;
}

export const formatDate = (str: string) => {
  if (!moment(str).isAfter(minDate))
    return '--';

  return moment(str).format("DD-MMM-YYYY");
}

export const formatDateToStore = (str: string) => {
  if (!moment(str).isAfter(minDate))
    return '--';

  return moment(str).format("YYYY-MM-DD");
}

export type sortFnType = (itemA: any, itemB: any) => -1 | 0 | 1;

export function valueOrDefault(value: unknown, defaultValue: string): string {
  if (value === undefined) { return defaultValue; }
  if (typeof value === 'object') {
    if (value === null) { return defaultValue; }
    if (moment.isMoment(value)) { return value.format('L'); }
    return defaultValue;
  }
  if (value === '') { return defaultValue; }
  return String(value);
}

export function parseBool(value: any) {
  if (typeof value === "boolean") return value;

  if (typeof value === "number") {
    return value === 1 ? true : value === 0 ? false : undefined;
  }

  if (typeof value != "string") return undefined;

  return value.toLowerCase() === 'true' ? true : false;
}

export function getCurrentLocale(context: IAppContext): string {
  let locale = 'en';
  if (context && typeof context.getLocale === 'function')
    locale = context.getLocale().localeName;
  return locale;

}

export function getCurrentTheme(context: IAppContext): IAppTheme {
  let theme = DefaultTheme;
  if (context && typeof context.getLocale === 'function')
    theme = context.getTheme();
  return theme;

}


export function isMockData(context: IAppContext): boolean {
  const filter = context.getConfigurationContext().getConfigurations().find(param => param.key === "IsMockData");
  return filter ? parseBool(filter.value) : false;
}
export function getApiBaseUrl(context: IAppContext): string {
  const filter = context.getConfigurationContext().getConfigurations().find(param => param.key === "API_ENDPOINT");
  return filter.value;
}

export function getInDashboardEditMode(context: IAppContext): boolean {
  let result = false;
  if (context && typeof context.getAppState === 'function')
    result = context.getAppState().inDashboardEditMode;
  return result;
}

export function getComponentKey(context: IAppContext): string {
  let result = 'N/A';
  if (context && typeof context.getConfigurationContext === 'function') {
    const filter = context.getConfigurationContext().getConfigurations().find(param => param.key === "COMPONENT_KEY");
    return filter ? filter.value : result;
  }
  return result;
}

export function canPinThisPage(context: IAppContext): boolean {
  let result = false;
  if (context && typeof context.getConfigurationContext === 'function') {
    const filter = context.getConfigurationContext().getConfigurations().find(param => param.key === "CAN_PIN_THIS_PAGE");
    return filter ? parseBool(filter.value) : result;
  }
  return result;
}

export function isPageAlreadyPinned(context: IAppContext): boolean {
  let result = true;
  if (context && typeof context.getConfigurationContext === 'function') {
    const filter = context.getConfigurationContext().getConfigurations().find(param => param.key === "IS_PAGE_ALREADY_PINNED");
    return filter ? parseBool(filter.value) : result;
  }
  return result;
}

export function getPinnedPageTileKey(context: IAppContext): string {
  let result = '';
  if (context && typeof context.getConfigurationContext === 'function') {
    const filter = context.getConfigurationContext().getConfigurations().find(param => param.key === "PINNED_PAGE_TILE_KEY");
    return filter ? filter.value : result;
  }
  return result;
}

export const KeyCodes = {
  tab: 9 as 9,
  enter: 13 as 13,
  left: 37 as 37,
  up: 38 as 38,
  right: 39 as 39,
  down: 40 as 40,
}


export function setGlobalContext(context: IAppContext, intl: any) {
  const globalStorage = GlobalStorage.getInstance();
  globalStorage.set('parentContext', context);
  globalStorage.set("intl", intl);
} 

export function getPatentContext(): IAppContext {
  const globalStorage = GlobalStorage.getInstance();
  return globalStorage.get('parentContext');
}

