
import React, { FC ,useState, useEffect} from "react";
import { 
  Panel, 
  PanelType, 
  PrimaryButton, 
  DetailsList, 
  IColumn, 
  SelectionMode,
  DetailsListLayoutMode,
  Text,Spinner,SpinnerSize,mergeStyleSets,IStyle
} from "@fluentui/react";
import { DelegationAPI } from "../../services/delegateapi";

interface DelegationAuditProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRecord: any;
  formatDate: (date: string) => string;
  delegationId: string;
  props: any;
}
const DelegationAudit: FC<DelegationAuditProps> = ({
  isOpen,
  onClose,
  selectedRecord,
  formatDate,
  delegationId,
  props
}) => {
  const [sorteddelegationAuditHistoryColumn, setSorteddelegationAuditHistoryColumn] = useState<string | null>(null); // Sorted column
  const [isSorteddelegationAuditHistoryColumnDescending, setIsSorteddelegationAuditHistoryColumnDescending] = useState<boolean>(false); // Sort order
  const [delegationAuditHistory, setDelegationAuditHistory] = useState<any[]>([]); // Delegation audit history state
  const { parentContext } = props;
  const [isPageLoading, setIsPageLoading] = useState(true);
  // Update the state whenever the prop delegationAuditHistory changes
  useEffect(() => {
    if (isOpen && parentContext && delegationId) {
      let api = new DelegationAPI();
      setIsPageLoading(true)
      api.getdelegationAuditHistory(parentContext,delegationId)
        .then((res: any) => {
          if (res.data && res.data !== "") {
            setDelegationAuditHistory(res.data);
          } 
        })
        .catch((e: any) => { })
        .finally(() => {setIsPageLoading(false);});
  }
  },[isOpen,parentContext, delegationId]);
 
  const auditColumns: IColumn[] = [
  
    {
      key: 'updatedDate',
      name: 'Updated On',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isSorted: sorteddelegationAuditHistoryColumn === "updatedDate",
      isSortedDescending: isSorteddelegationAuditHistoryColumnDescending,
      onColumnClick: () => onColumnClick("updatedDate"),
      onRenderHeader: (props, defaultRender) => {
        const ariaSort =sorteddelegationAuditHistoryColumn === "updatedDate"
            ? isSorteddelegationAuditHistoryColumnDescending ? "descending" : "ascending"
            : "none";
        const ariaLabel = `Updated On, ${ariaSort === "none" ? "not sorted" : `sorted ${ariaSort}`}`;
        return (
          <th
            aria-sort={ariaSort}
            aria-label={ariaLabel}
            onClick={() => onColumnClick("updatedDate")}
          >
            {defaultRender?.(props)}
            {sorteddelegationAuditHistoryColumn === "updatedDate" && (
              <span aria-hidden="true">
                {isSorteddelegationAuditHistoryColumnDescending }
              </span>
            )}
          </th>
        );
      },
      onRender: (item) =>item.delegation.updatedDate ? formatDate(item.delegation.updatedDate) : 'N/A',
    },
    {
      key: 'updatedBy',
      name: 'Changes',
      minWidth: 220,
      maxWidth: 330,
      isResizable: true,
      isSorted: sorteddelegationAuditHistoryColumn === "updatedBy",
      isSortedDescending: isSorteddelegationAuditHistoryColumnDescending,
      onColumnClick: () => onColumnClick("updatedBy"),
      onRenderHeader: (props, defaultRender) => {
        const ariaSort = sorteddelegationAuditHistoryColumn === "updatedBy"
            ? isSorteddelegationAuditHistoryColumnDescending ? "descending" : "ascending"
            : "none";
        const ariaLabel = `Changes, ${ariaSort === "none" ? "not sorted" : `sorted ${ariaSort}`}`;
        return (
          <th
            aria-sort={ariaSort}
            aria-label={ariaLabel}
            onClick={() => onColumnClick("updatedBy")}
          >
            {defaultRender?.(props)}
            {sorteddelegationAuditHistoryColumn === "updatedBy" && (
              <span aria-hidden="true">
                {isSorteddelegationAuditHistoryColumnDescending}
              </span>
            )}
          </th>
        );
      },
      onRender: (item) => {
        const action = item.delegation.action || 'Unknown action';
        const modifiedBy = item.delegation.modifiedBy || 'Unknown user';
       if (action === 'Created') {
        return `New delegation ${action} by ${modifiedBy}`;
    } else {
      return `Delegation ${action} by ${modifiedBy}`; 
    }
  }
    },
  ];
  const onColumnClick = (columnName: string) => {
    const isCurrentlySortedDescending =
      sorteddelegationAuditHistoryColumn === columnName && isSorteddelegationAuditHistoryColumnDescending;
 
    setSorteddelegationAuditHistoryColumn(columnName);
    setIsSorteddelegationAuditHistoryColumnDescending(!isCurrentlySortedDescending);
 
    // Sort the records based on the clicked column
    const sortedRecords = [...delegationAuditHistory].sort((a, b) => {
      if (columnName === "updatedDate") {
        const aValue = a[columnName];
        const bValue = b[columnName];
        const aDate = new Date(aValue).getTime();
        const bDate = new Date(bValue).getTime();
        if (isCurrentlySortedDescending) {
            return bDate - aDate;
        } else {
            return aDate - bDate;
        }
      }else if (columnName === "updatedBy") {
          const aValue = (a.delegation?.action || "").toLowerCase();
          const bValue = (b.delegation?.action || "").toLowerCase();
          return isCurrentlySortedDescending
            ? bValue.localeCompare(aValue)
            : aValue.localeCompare(bValue);
    } else {
        // Handle sorting for other columns if needed
        return 0; // Add a default return statement for other columns
    }
    });    
    setDelegationAuditHistory(sortedRecords);
  };
  const spacedparagraphClass=mergeStyleSets({
    spacedparagraph :{marginBottom: '10px'} as IStyle
})
  return (  
    <Panel
      isOpen={isOpen}
      onDismiss={onClose}
      type={PanelType.custom}
      customWidth="650px"
      headerText="Record Details"
      closeButtonAriaLabel="Close"
      aria-label="Delegation Audit Panel"
      styles={{
        root: {
          height: "100%",
          marginTop: "50px",
          width: "100%",
        },
        scrollableContent: {
          height: "90%",
          overflowX: "auto",
        },
      }}
      isFooterAtBottom={true}
    >
      <div>
        {selectedRecord && (
          <div>
            {selectedRecord.section === "onBehalfDelegates" && (
              <p className={spacedparagraphClass.spacedparagraph}>
                <strong>Delegator Name:</strong> {selectedRecord.item.delegator}
              </p>
            )}
            <p className={spacedparagraphClass.spacedparagraph}>
              <strong>Delegate Name:</strong> {selectedRecord.item.delegate}
            </p>
            <p className={spacedparagraphClass.spacedparagraph}>
              <strong>Application:</strong> {selectedRecord.item.application}
            </p>
            <p className={spacedparagraphClass.spacedparagraph}>
              <strong>Permissions:</strong> {selectedRecord.item.permissions}
            </p>
            <p className={spacedparagraphClass.spacedparagraph}>
              <strong>Start Date:</strong> {formatDate(selectedRecord.item.startDate)}
            </p>
            <p className={spacedparagraphClass.spacedparagraph}>
              <strong>End Date:</strong> {formatDate(selectedRecord.item.endDate)}
            </p>
            <p className={spacedparagraphClass.spacedparagraph}>
              <strong>Notes:</strong> {selectedRecord.item.notes}
            </p>
            
            {isPageLoading ? (
        <Spinner
          label="Loading"
          size={SpinnerSize.large}
          styles={{ root: { marginTop: '20px' } }}
          ariaLive="assertive"
          aria-label="Loading delegation audit history"
        />
      ) : (
           <div>
            {/* <h3>Delegation History</h3> */}
            <h3 aria-label="Delegation History" aria-level={3} style={{ marginTop: '20px' }}>Delegation History</h3>
            <DetailsList
              items={delegationAuditHistory && delegationAuditHistory.length > 0 ? delegationAuditHistory : []}
              columns={auditColumns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.fixedColumns}
              selectionMode={SelectionMode.none}  
              styles={{
                root: {
                  overflowX: 'auto',
                  selectors: {
                    '.ms-DetailsRow-cell': {
                      height: 'auto',
                      minHeight: '36px'
                    }
                  }
                }
              }}
            />
            {(!delegationAuditHistory || delegationAuditHistory.length === 0) && (
              <Text style={{ padding: '10px', display: 'block' }}>Delegation History Not vailable.</Text>
            )}
            </div>
      )}

          </div>
        )}
      </div>
    </Panel>
  );
};  
export default DelegationAudit;