import moment from 'moment';
import { IAppContext, IAppTheme } from '@msx/platform-services'
import { DefaultTheme } from '@msx/react-ui-component';
import { graphApiToken } from '../provider/authprovider';

var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken: string) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done: any) => {
            done(null, accessToken);
        }
    });

    return client;
}

export async function getGraphUserData(filterText: any, parentContext: IAppContext) {
    const accessToken = await graphApiToken(parentContext);
    const client = getAuthenticatedClient(accessToken);

    let userList = await client
    .api(`https://graph.microsoft.com/v1.0/users?$filter=startswith(userPrincipalName, '${filterText}') or startswith(displayName, '${filterText}')`)
    .get();
    return userList;
}