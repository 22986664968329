import { getAccessToken } from "../../provider/authprovider";
import jwt from "jsonwebtoken";
import { isMobile, isTablet } from 'react-device-detect';

export default class TelemetryService {
  static checktelemetryClient(telemetryClient: any) {
    if (telemetryClient === null) {
      throw new Error("telemetryClient cannot be empty");
      // return;
    }
  }

   static async addCommonProperties(parentContex, appName,propertiesDictionary) {
    const accessToken = await getAccessToken(parentContex);
    const decodedToken = jwt.decode(accessToken)
    let formFactor = 'Desktop';
    if (isMobile) {
      formFactor = 'Mobile';
    } else if (isTablet) {
      formFactor = 'Tablet';
    }
    return {
      ...propertiesDictionary,
      LoggedInUser: decodedToken.name,
     ApplicationName: appName,
      FormFactor: formFactor,
      BrowserName: this.getBrowserName(),
    };
  }
  static getBrowserName() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Firefox') > -1) {
        return 'Firefox';
    } else if (userAgent.indexOf('Edg') > -1) {
        return 'Edge';
    } else if (userAgent.indexOf('Chrome') > -1) {
        return 'Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
        return 'Safari';
    } else {
        return 'Unknown';
    }
  }

  static async trackTrace(
    parentContext, 
    telemetryClient,
    message: string,
    appName:string,
    customProperties: object = {}
  ) {
    this.checktelemetryClient(telemetryClient);
    const properties = await this.addCommonProperties(parentContext, appName,customProperties);
    telemetryClient.trackTrace({ message, properties });
  }

  static async trackEvent(
    parentContext,
    telemetryClient,
    EventName: string,
    appName:string,
    customProperties: object = {}
  ) {
    this.checktelemetryClient(telemetryClient);
    const properties = await this.addCommonProperties(parentContext,appName,customProperties);
    telemetryClient.trackEvent({ name: EventName, properties });
  }

  static async trackException(
    parentContext,
    telemetryClient,
    EventName: string,
    exception: string,
    exceptionType: string,
    failedMethod: string,
    appName:string,
    customProperties: object = {}
  ) {
    this.checktelemetryClient(telemetryClient);
    const properties = await this.addCommonProperties(parentContext, appName,{
      ...customProperties,
      EventName
    });
    

    telemetryClient.trackException({
      exception,
      properties: {
        ...properties,
        ExceptionType: exceptionType,
        FailedMethod: failedMethod
      }
    });}
}
