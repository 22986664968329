import React, { FC, useEffect, useState, useContext } from 'react';
import { IDelegationDashboardProps } from '../delegationDashboard.types';
import { injectIntl } from 'react-intl';
import { Stack, DetailsList, SelectionMode,TooltipDelay,DirectionalHint,IStyle,mergeStyleSets, DetailsListLayoutMode } from '@fluentui/react';
import { DelegationAPI } from '../../services/delegateapi';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { isMockData, mockDataMessage } from "../../utils";
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import TelemetryService from "../../services/TelemetryService/TelemetryService";
import TelemetryContext from "../../context/TelemetryContext";
import Enums from "../../services/TelemetryService/Enums";
import { EmptyResults } from '../shared/EmptyResult';

const infoIconClass=mergeStyleSets({
    infoIcon :{cursor: 'pointer', verticalAlign:'middle'} as IStyle
})
const PeopleYouSupportComponent: FC<IDelegationDashboardProps & { updateRecordCount: (count: number) => void }> = (props) => {
    const { parentContext, updateRecordCount } = props;
    const [records, setRecords] = useState([]);
    const [ispeopleYouSupportLoading, setIspeopleYouSupportLoading] = useState(true);
    const [sortedColumn, setSortedColumn] = useState(null);  // Currently sorted column
    const [isSortedDescending, setIsSortedDescending] = useState(false);  // Sort order (ascending/descending)
    const [loginUserName, SetloginUserName] = useState('');
    const ismockdata = isMockData(parentContext);
    const detailsListId = "peopleYouSupportList";
    const [flag, setFlag] = React.useState(true);
    const [ariaLabel, setAriaLabel] = useState("People you support Loading");
    const { telemetryClient } = useContext(TelemetryContext);

    useEffect(() => {
        let api = new DelegationAPI();
        let logData: any = {};
        TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.GetPeopleYouSupportProcessing, props.appName, logData)
        api
            .getPeopleYouSupport(parentContext, ismockdata)
            .then((res: any) => {
                setRecords(res.data);
                const count = res.data.length;
                for (let i = 0; i < res.data.length; i++) {
                    let logData: any = {};
                    logData[Enums.LogDataKey.PartnerName] = res.data[i].partnerName;
                    logData[Enums.LogDataKey.ConsumerDocTypeId] = res.data[i].consumerDocTypeId;
                    logData[Enums.LogDataKey.LoggedInUserEmail] = res.data[i].loggedInUser.Email;
                    logData[Enums.LogDataKey.LoggedInUserObjectId] = res.data[i].loggedInUser.Id;
                    logData[Enums.LogDataKey.LoggedInUserName] = res.data[i].loggedInUser.Name;
                    logData[Enums.LogDataKey.DelegateEmail] = res.data[i].delegate.Email;
                    logData[Enums.LogDataKey.DelegateObjectId] = res.data[i].delegate.Id;
                    logData[Enums.LogDataKey.DelegateName] = res.data[i].delegate.Name;
                    logData[Enums.LogDataKey.DelegatorEmail] = res.data[i].delegator.Email;
                    logData[Enums.LogDataKey.DelegatorObjectId] = res.data[i].delegator.Id;
                    logData[Enums.LogDataKey.DelegatorName] = res.data[i].delegator.Name;
                    logData[Enums.LogDataKey.Permissions] = res.data[i].permissions;
                    logData[Enums.LogDataKey.StartDate] = res.data[i].startDate;
                    logData[Enums.LogDataKey.EndDate] = res.data[i].endDate;
                    logData[Enums.LogDataKey.LoggedInUserNotes] = res.data[i].loggedInUserNotes;
                    logData[Enums.LogDataKey.IsExpired] = res.data[i].isExpired;
                    logData[Enums.LogDataKey.IsLoggedInUserRemoved] = res.data[i].isLoggedInUserRemoved;
                    logData[Enums.LogDataKey.DelegationStatus] = res.data[i].delegationStatus;
                    TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.GetPeopleYouSupportSuccess, props.appName, logData)
                }
                logData = {};
                TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.GetLoggedInUserNameProcessing, props.appName, logData)
                api.getLoggedInUserName(parentContext)
                    .then((res: any) => {
                        SetloginUserName(res);
                        if (res && res.data) {
                            logData[Enums.LogDataKey.LoginUserName] = res;
                        }
                        TelemetryService.trackEvent(parentContext, telemetryClient, Enums.TrackingEvent.GetLoggedInUserNameSuccess, props.appName, logData)
                    })
                setAriaLabel(label === "Loading" ? "People you support Loading" : "Waiting for data...");
            }).catch((e: any) => {
                const stackTrace = e.stack || "";
                const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
                const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

                // Extracting Exception type
                const exceptionType = e.message ? e.message.split(":")[0] : "";
                TelemetryService.trackException(
                    parentContext,
                    telemetryClient,
                    Enums.TrackingEvent.GetPeopleYouSupportFail,
                    e.response ? e.response.data : "",
                    exceptionType,
                    failedMethod, props.appName
                );
            }).finally(() => {
                setIspeopleYouSupportLoading(false);
            });
    }, [parentContext]);

    const formatDate = (date) => {
        return new Date(date).toLocaleString([], {
            year: "numeric",
            month: "short",
            day: "2-digit", 
            hour: "2-digit",
            minute: "2-digit"
        });
    };
    const columns = [
        {
            key: "delegator",
            name: "Delegator",
            fieldName: "delegator",
            isResizable: true,
            role: "columnheader",
            minWidth: 100,  // Adjust this value based on your needs
            minHeight: 60,
            maxWidth: 100,
            isSorted: sortedColumn === "delegator",
            isSortedDescending: isSortedDescending,
            onColumnClick: () => onColumnClick("delegator"),
            ariaSort: sortedColumn === "delegator" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
            ariaLabel: `delegator column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
        },
        {
            key: "partnerName",
            name: "Application",
            fieldName: "partnerName",
            isResizable: true,
            role: "columnheader",
            minWidth: 100,
            minHeight: 12,
            maxwidth: 60,
            isSorted: sortedColumn === "partnerName",
            isSortedDescending: isSortedDescending,
            onColumnClick: () => onColumnClick("partnerName"),
            ariaSort: sortedColumn === "partnerName" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
            ariaLabel: `partnerName column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
        },
        {
            key: "permissions",
            name: "Permission",
            fieldName: "permissions",
            isResizable: true,
            role: "columnheader",
            minWidth: 125,
            minHeight: 12,
            maxwidth: 150,
            isSorted: sortedColumn === "permissions",
            isSortedDescending: isSortedDescending,
            onColumnClick: () => onColumnClick("permissions"),
            ariaSort: sortedColumn === "permissions" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
            ariaLabel: `permissions column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
        },
        {
            key: "startDate",
            name: "Start Date",
            fieldName: "startDate",
            isResizable: true,
            role: "columnheader",
            minWidth: 135,
            minHeight: 12,
            maxwidth: 150,
            isSorted: sortedColumn === "startDate",
            isSortedDescending: isSortedDescending,
            onColumnClick: () => onColumnClick("startDate"),
            ariaSort: sortedColumn === "startDate" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
            ariaLabel: `startDate column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
        },
        {
            key: "endDate",
            name: "End Date",
            fieldName: "endDate",
            isResizable: true,
            role: "columnheader",
            minWidth: 135,
            minHeight: 12,
            maxwidth: 150,
            isSorted: sortedColumn === "endDate",
            isSortedDescending: isSortedDescending,
            onColumnClick: () => onColumnClick("endDate"),
            ariaSort: sortedColumn === "endDate" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
            ariaLabel: `endDate column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
        },
        {
            key: "notes",
            name: "Notes",
            fieldName: "notes",
            isResizable: true,
            role: "columnheader",
            minWidth: 100,
            minHeight: 12,
            maxwidth: 150,
            isSorted: sortedColumn === "notes",
            isSortedDescending: isSortedDescending,
            onColumnClick: () => onColumnClick("notes"),
            ariaSort: sortedColumn === "notes" ? (isSortedDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
            ariaLabel: `notes column is ${sortedColumn ? (isSortedDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
        },
        {
            key: "email",
            name: "Email",
            fieldName: "email",
            minWidth: 100,
            minHeight: 12,
            maxwidth: 150,
            role: "button",
            tabIndex: 0,
            onRender: (item) => (
                <TooltipHost
                    content="Click here to contact the delegator"
                    id={`${detailsListId}_${item.id}`}
                >
                    <button
                        style={{
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleEmailClick(item.email, item.delegator, item.partnerName, item.permissions, item.delegator, item.startDate, item.endDate, item.notes)}
                        tabIndex={0} // Make the button focusable
                        onKeyDown={(e) => {
                            // Trigger email click action on Enter key
                            if (e.key === 'Enter') {
                                handleEmailClick(item.email, item.delegator, item.partnerName, item.permissions, item.delegator, item.startDate, item.endDate, item.notes);
                            }
                        }}
                        aria-label={`Send email to ${item.delegator}`}
                        aria-describedby={`${detailsListId}_${item.id}`}
                        aria-labelledby={`${detailsListId}_${item.id}`}
                        role="button"
                    >
                        <Icon
                            iconName="Mail"
                            style={{
                                color: '#0078d4', // Use the same blue color as the delete icon
                                fontSize: '16px', // Use the same font size as the delete icon
                            }}
                        />
                    </button>
                </TooltipHost>
            ),
        }
    ];
    const handleEmailClick = (delegatorEmail, delegateName, partnerName, permissions, DelegatorName, startDate, endDate, loggedInUserNotes) => {
        const subject = `Delegation has been created by ${loginUserName}`;
        const description = `
Delegation Details:

Delegator: ${DelegatorName} 
Start Date: ${startDate}
End Date: ${endDate}
Application: ${partnerName}
Permission: ${permissions}
Notes: ${loggedInUserNotes ? loggedInUserNotes : ''}
`;
        window.location.href = `mailto:${delegatorEmail}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(description)}`;
    };

    const onColumnClick = (columnName) => {
        const isCurrentlySortedDescending = sortedColumn === columnName && isSortedDescending;
        setSortedColumn(columnName);
        setIsSortedDescending(!isCurrentlySortedDescending);
        // Sort the records based on the clicked column
        const sortedRecords = [...records].sort((a, b) => {
            if (columnName === "delegator") {
                const aValue = a.delegator.name.toLowerCase();
                const bValue = b.delegator.name.toLowerCase();

                if (isCurrentlySortedDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else if (columnName === "partnerName") {
                const aValue = a.partnerName.toLowerCase();
                const bValue = b.partnerName.toLowerCase();

                if (isCurrentlySortedDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            }
            else if (columnName === "permissions") {
                const aValue = a.permissions.join(", ").toLowerCase();
                const bValue = b.permissions.join(", ").toLowerCase();

                if (isCurrentlySortedDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            }
            else if (columnName === "startDate" || columnName === "endDate") {
                const aValue = a[columnName];
                const bValue = b[columnName];
                const aDate = new Date(aValue).getTime();
                const bDate = new Date(bValue).getTime();
                if (isCurrentlySortedDescending) {
                    return bDate - aDate;
                } else {
                    return aDate - bDate;
                }
            }
            else if (columnName === "notes") {
                const aValue = (a.notes || "").toLowerCase();
                const bValue = (b.notes || "").toLowerCase();

                if (isCurrentlySortedDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            }
            else {
                // Handle sorting for other columns if needed
                return 0; // Add a default return statement for other columns
            }
        });
        setRecords(sortedRecords);
    };
    const label = flag ? "Loading" : "Wait...";
    let submitloader =
        ispeopleYouSupportLoading ?
            (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    backgroundColor: 'rgba(229, 229, 229, 0.5)',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                    aria-label="speak this"
                    aria-live="assertive"
                >
                    <Spinner
                        id="peoplesupport-spinner"
                        label={label}
                        labelPosition="right"
                        size={SpinnerSize.large}
                        ariaLabel={ariaLabel}
                    />
                </div>
            )
            : (
                ""
            );
    const renderContent = () => {
        if (ispeopleYouSupportLoading) {
            return submitloader;
          }
        return (
            <>
                <div id={detailsListId}>
                    <div>
                        <div style={{display:'inline-block'}}>
                            <h2 style={{ fontSize: '18px', marginBottom: '0',marginTop:'10px' }}>
                                    People You Support
                                    
                            </h2>
                        </div>
                        <div style={{display:'inline', fontSize:'18px'}}>
                            <TooltipHost
                                        content="These are people who delegated to you"
                                        delay={TooltipDelay.zero}
                                        directionalHint={DirectionalHint.topCenter}
                                        id="tooltip-peopleYouSupport-delegations" // Unique ID for the tooltip content
                                    >
                                        <span
                                        role="tooltip"
                                        tabIndex={0} // Makes the icon focusable with the keyboard
                                        aria-label="People You Support"
                                        aria-labelledby="tooltip-peopleYouSupport-delegations"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                            e.preventDefault();
                                    
                                            // Cast e.target to HTMLElement
                                            const targetElement = e.target as HTMLElement;
                                    
                                            if (targetElement.click) {
                                                targetElement.click(); // Now TypeScript will not complain
                                            }
                                            }
                                        }}
                                        // Add margin to create space between text and icon
                                        style={{ marginLeft: '8px' }} // Adjust the value as needed
                                        >                        
                                        <Icon iconName="Info" className={infoIconClass.infoIcon} />
                                        </span>
                                    </TooltipHost>
                         </div>
                </div>
                    {records.length > 0 ? (
                        <div>
                        <DetailsList
                            items={records.map(
                                ({
                                    id,
                                    delegator: { email, name },  // delegatename i add for the name it will  display on Delegate column
                                    partnerName,
                                    permissions,
                                    startDate,
                                    endDate,
                                    loggedInUserNotes,
                                }) => {
                                    const [firstName, lastName] = name ? name.split(' ') : "";
                                    const username = email ? email.split('@')[0] : "";
                                    return {
                                        id,
                                        email,
                                        delegator: `${firstName} ${lastName}`,
                                        partnerName: partnerName ? partnerName : "app1",
                                        permissions: permissions ? permissions.join(", ") : "",
                                        startDate: formatDate(startDate), // Format the start date
                                        endDate: formatDate(endDate),
                                        notes: loggedInUserNotes,
                                        username: username,
                                    };
                                }
                            )}
                            columns={columns}
                            styles={{
                                contentWrapper: {
                                  maxHeight: window.innerHeight/2,
                                  height:'auto',
                                  overflowX:'hidden'
                                }
                              }}
                            ariaLabelForGrid="People You Support Table" 
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={(item, index, column) => {
                                if (column.key === 'delegator') {
                                    return (
                                        <TooltipHost content={item.username}>
                                            <div id={`${detailsListId}_${item.id}`}  style={{ cursor: 'pointer' }}>{item[column.fieldName]}</div>
                                        </TooltipHost>
                                    );
                                }
                                return item[column.fieldName];
                            }}
                        />
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <EmptyResults message="No one has delegated to you" />
                        </div>
                    )}
                </div>
            </>
        );
    }

    const renderMain = (): JSX.Element => {
        return (
            <Stack >
                {renderContent()}
            </Stack>
        )
    }
    return renderMain();
};

export const PeopleYouSupport = injectIntl(PeopleYouSupportComponent);