import React, { FC, useEffect, useState } from "react";
import {
  Stack,
  DetailsList,
  DetailsRow,
  SelectionMode,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  IDetailsRowProps,
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  Icon,
  IStyle,
  PrimaryButton,
  DetailsListLayoutMode,
} from "@fluentui/react";
import { injectIntl } from "react-intl";
import { IDelegationDashboardProps } from "../delegationDashboard.types";
import { DelegationAPI } from "../../services/delegateapi";
import { EmptyResults } from "../shared/EmptyResult";
import DelegationAudit from "./delegationAudit"; 
const PostDelegatesComponent: FC<IDelegationDashboardProps  & { updateRecordCount: (count: number) => void } > = (props) => {
  const { parentContext, updateRecordCount } = props;
  const [onBehalfDelegationHistory, setOnBehalfDelegationHistory] = useState([]);
  const [yourDelegationHistory, setYourDelegationHistory] = useState([]);
  const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [sortedonBehalfDelegatesColumn, setSortedonBehalfDelegatesColumn] = useState(null); // Currently sorted column
  const [sortedyourDelegatesColumn, setSortedyourDelegatesColumn] = useState(null); // Currently sorted column
  const [isSortedonBehalfDelegatesDescending, setIsSortedonBehalfDelegatesDescending] = useState(false); // Sort order (ascending/descending)
  const [isSortedyourDelegatesDescending, setIsSortedyourDelegatesDescending] = useState(false); // Sort order (ascending/descending)
 const [delegationId, setDelegationId] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [flag, setFlag] = React.useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const RECORD_PER_PAGE = 4;
  const getPaginatedItems = (items: any[], page: number) => {
    const startIndex = (page - 1) * RECORD_PER_PAGE;
    const endIndex = startIndex + RECORD_PER_PAGE;
    return items.slice(startIndex, endIndex);
  };
   
  const getTotalPages = (items: any[]) => {
    return Math.ceil(items.length / RECORD_PER_PAGE);
  };
  const renderPagination = (items: any[], listType: string) => {
    const totalPages = getTotalPages(items);
    return (
     
      <Stack horizontal tokens={{ childrenGap: 10 }} className={personaStyle.paginationContainer}>
 
 
        <PrimaryButton
          text="Previous"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        <span>Page {currentPage} of {totalPages}</span>
        <PrimaryButton
          text="Next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Stack>
    );
  };
  const personaStyle = mergeStyleSets({
    iconButton: { marginTop: 5 },
    tableContainer: {
        marginBottom: 20, // Add margin below the table
      },
      paginationContainer: {
        marginTop: 20, // Add margin above the pagination controls
      },
    row: {
      selectors: {
        ":hover": {
          color: "#0078d4",
          cursor: "pointer",
        },
      },
    },
    icon: {
      fontSize: 16,
      marginLeft: 8,
      opacity: 0, 
      selectors: {
        ".ms-DetailsRow:hover &": {
          opacity: 1, 
        },
      },
    },
  });

  const infoIconClass=mergeStyleSets({
    infoIcon :{cursor: 'pointer', verticalAlign:'middle'} as IStyle
})
  const formatDate = (date) => {
    return new Date(date).toLocaleString([], {
        year: "numeric",
        month: "short",
        day: "2-digit", 
        hour: "2-digit",
        minute: "2-digit"
    });
};
const renderRow = (props: IDetailsRowProps) => {
  return (
    <DetailsRow
      {...props}
      className={personaStyle.row}
      styles={{
        root: {
          cursor: 'pointer',
          cell: {
        selectors: {
          ":hover": {
            color: "rgb(0, 108, 190)",
          },
        },
      },
        },
      }}
      
    />
  );
};
  const columnsForonBehalfDelegates = [
    {
        key: "delegator",
        name: "Delegator",
        fieldName: "delegator",
        isResizable: true,
        minWidth: 100,  // Adjust this value based on your needs
        minHeight: 10,
        maxWidth: 130,
        isSorted: sortedonBehalfDelegatesColumn === "delegator",
        isSortedDescending: isSortedonBehalfDelegatesDescending,
        onColumnClick: () => onColumnClick("delegator", "isonBehalfDelegates"),
        ariaSort: sortedonBehalfDelegatesColumn === "delegator" ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        isFilterable: true,
        ariaLabel: `delegator column is ${sortedonBehalfDelegatesColumn ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "delegate",
        name: "Delegate",
        fieldName: "delegate",
        isResizable: true,
        minWidth: 100,  // Adjust this value based on your needs
        minHeight: 10,
        maxWidth: 130,
        isSorted: sortedonBehalfDelegatesColumn === "delegate",
        isSortedDescending: isSortedonBehalfDelegatesDescending,
        onColumnClick: () => onColumnClick("delegate", "isonBehalfDelegates"),
        ariaSort: sortedonBehalfDelegatesColumn === "delegate" ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        isFilterable: true,
        ariaLabel: `delegate column is ${sortedonBehalfDelegatesColumn ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "application",
        name: "Application",
        fieldName: "application",
        isResizable: true,
        minWidth: 135,
        minHeight: 10,
        maxWidth: 200,
        isSorted: sortedonBehalfDelegatesColumn === "application",
        isSortedDescending: isSortedonBehalfDelegatesDescending,
        onColumnClick: () => onColumnClick("application", "isonBehalfDelegates"),
        ariaSort: sortedonBehalfDelegatesColumn === "application" ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `application column is ${sortedonBehalfDelegatesColumn ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "permissions",
        name: "Permission",
        fieldName: "permissions",
        isResizable: true,
        minWidth: 100,
        minHeight: 10,
        maxWidth: 170,
        isSorted: sortedonBehalfDelegatesColumn === "permissions",
        isSortedDescending: isSortedonBehalfDelegatesDescending,
        onColumnClick: () => onColumnClick("permissions", "isonBehalfDelegates"),
        ariaSort: sortedonBehalfDelegatesColumn === "permissions" ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `permissions column is ${sortedonBehalfDelegatesColumn ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "startDate",
        name: "Start Date",
        fieldName: "startDate",
        isResizable: true,
        minWidth: 120,
        minHeight: 10,
        maxWidth: 150,
        isSorted: sortedonBehalfDelegatesColumn === "startDate",
        isSortedDescending: isSortedonBehalfDelegatesDescending,
        onColumnClick: () => onColumnClick("startDate", "isonBehalfDelegates"),
        ariaSort: sortedonBehalfDelegatesColumn === "startDate" ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `startDate column is ${sortedonBehalfDelegatesColumn ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
      key: "endDate",
      name: "End Date",
      fieldName: "endDate",
      isResizable: true,
      minWidth: 120,
      minHeight: 10,
      maxWidth: 150,
      isSorted: sortedonBehalfDelegatesColumn === "endDate",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      onColumnClick: () => onColumnClick("endDate", "isonBehalfDelegates"),
      ariaSort: sortedonBehalfDelegatesColumn === "endDate" ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `endDate column is ${sortedonBehalfDelegatesColumn ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "notes",
        name: "Notes",
        fieldName: "notes",
        isResizable: true,
        minWidth: 70,
        minHeight: 10,
        maxWidth: 100,
        isSorted: sortedonBehalfDelegatesColumn === "notes",
        isSortedDescending: isSortedonBehalfDelegatesDescending,
        onColumnClick: () => onColumnClick("notes", "isonBehalfDelegates"),
        ariaSort: sortedonBehalfDelegatesColumn === "notes" ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `notes column is ${sortedonBehalfDelegatesColumn ? (isSortedonBehalfDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    }
];
const columnsForyourDelegates = [
    {
        key: "delegate",
        name: "Delegate",
        fieldName: "delegate",
        isResizable: true,
        minWidth: 100,  // Adjust this value based on your needs
        minHeight: 60,
        maxWidth: 150,
        isSorted: sortedyourDelegatesColumn === "delegate",
        isSortedDescending: isSortedyourDelegatesDescending,
        onColumnClick: () => onColumnClick("delegate", "isyourDelegates"),
        ariaSort: sortedyourDelegatesColumn === "delegate" ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        isFilterable: true,
        ariaLabel: `delegate column is ${sortedyourDelegatesColumn ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "application",
        name: "Application",
        fieldName: "application",
        isResizable: true,
        minWidth: 140,
        minHeight: 12,
        maxWidth: 180,
        isSorted: sortedyourDelegatesColumn === "application",
        isSortedDescending: isSortedyourDelegatesDescending,
        onColumnClick: () => onColumnClick("application", "isyourDelegates"),
        ariaSort: sortedyourDelegatesColumn === "application" ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `application column is ${sortedyourDelegatesColumn ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "permissions",
        name: "Permission",
        fieldName: "permissions",
        isResizable: true,
        minWidth: 125,
        minHeight: 12,
        maxWidth: 170,
        isSorted: sortedyourDelegatesColumn === "permissions",
        isSortedDescending: isSortedyourDelegatesDescending,
        onColumnClick: () => onColumnClick("permissions", "isyourDelegates"),
        ariaSort: sortedyourDelegatesColumn === "permissions" ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `permissions column is ${sortedyourDelegatesColumn ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "startDate",
        name: "Start Date",
        fieldName: "startDate",
        isResizable: true,
        minWidth: 100,
        minHeight: 12,
        maxWidth: 150,
        isSorted: sortedyourDelegatesColumn === "startDate",
        isSortedDescending: isSortedyourDelegatesDescending,
        onColumnClick: () => onColumnClick("startDate", "isyourDelegates"),
        ariaSort: sortedyourDelegatesColumn === "startDate" ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `startDate column is ${sortedyourDelegatesColumn ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
      key: "endDate",
      name: "End Date",
      fieldName: "endDate",
      isResizable: true,
      minWidth: 100,
      minHeight: 12,
      maxWidth: 150,
      isSorted: sortedyourDelegatesColumn === "endDate",
      isSortedDescending: isSortedyourDelegatesDescending,
      onColumnClick: () => onColumnClick("endDate", "isyourDelegates"),
      ariaSort: sortedyourDelegatesColumn === "endDate" ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
      ariaLabel: `endDate column is ${sortedyourDelegatesColumn ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
    {
        key: "notes",
        name: "Notes",
        fieldName: "notes",
        isResizable: true,
        minWidth: 70,
        minHeight: 12,
        maxWidth: 100,
        isSorted: sortedyourDelegatesColumn === "notes",
        isSortedDescending: isSortedyourDelegatesDescending,
        onColumnClick: () => onColumnClick("notes", "isyourDelegates"),
        ariaSort: sortedyourDelegatesColumn === "notes" ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "none",  // Update sort status for screen readers
        ariaLabel: `notes column is ${sortedyourDelegatesColumn ? (isSortedyourDelegatesDescending ? "descending" : "ascending") : "not sorted"}. Press Enter to sort this column.`,
    },
  ];
 
const onColumnClick = (columnName, value) => {
    if (value === "isonBehalfDelegates") {
        const isCurrentlySortedonBehalfDelegatesDescending =
            sortedonBehalfDelegatesColumn === columnName && isSortedonBehalfDelegatesDescending;
        setSortedonBehalfDelegatesColumn(columnName);
        setIsSortedonBehalfDelegatesDescending(!isCurrentlySortedonBehalfDelegatesDescending);
        // Sort the records based on the clicked column
        const sortedonBehalfDelegatesRecords = [...onBehalfDelegationHistory].sort((a, b) => {
            if (columnName === "delegator") {
                const aValue = a.delegator.name.toLowerCase();
                const bValue = b.delegator.name.toLowerCase();
                if (isCurrentlySortedonBehalfDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            }
            else if (columnName === "delegate") {
                const aValue = a.delegate.name.toLowerCase();
                const bValue = b.delegate.name.toLowerCase();
                if (isCurrentlySortedonBehalfDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else if (columnName === "application") {
                const aValue = a.partnerName.toLowerCase();
                const bValue = b.partnerName.toLowerCase();
                if (isCurrentlySortedonBehalfDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else if (columnName === "permissions") {
                const aValue = a.permissions.join(", ").toLowerCase();
                const bValue = b.permissions.join(", ").toLowerCase();
                if (isCurrentlySortedonBehalfDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else if (columnName === "startDate" || columnName === "endDate") {
                const aValue = a[columnName];
                const bValue = b[columnName];
                const aDate = new Date(aValue).getTime();
                const bDate = new Date(bValue).getTime();
                if (isCurrentlySortedonBehalfDelegatesDescending) {
                    return bDate - aDate;
                } else {
                    return aDate - bDate;
                }
            } else if (columnName === "notes") {
                const aValue = (a.loggedInUserNotes || "").toLowerCase();
                const bValue = (b.loggedInUserNotes || "").toLowerCase();
 
                if (isCurrentlySortedonBehalfDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else {
                // Handle sorting for other columns if needed
                return 0; // Add a default return statement for other columns
            }
        });
        setOnBehalfDelegationHistory(sortedonBehalfDelegatesRecords);
    } else if (value === "isyourDelegates") {
        const isCurrentlySortedyourDelegatesDescending =
            sortedyourDelegatesColumn === columnName && isSortedyourDelegatesDescending;
        setSortedyourDelegatesColumn(columnName);
        setIsSortedyourDelegatesDescending(!isCurrentlySortedyourDelegatesDescending);
 
        const sortedyourDelegatesRecords = [...yourDelegationHistory].sort((a, b) => {
            if (columnName === "delegate") {
                const aValue = a.delegate.name.toLowerCase();
                const bValue = b.delegate.name.toLowerCase();
                if (isCurrentlySortedyourDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else if (columnName === "application") {
                const aValue = a.partnerName.toLowerCase();
                const bValue = b.partnerName.toLowerCase();
                if (isCurrentlySortedyourDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else if (columnName === "permissions") {
                const aValue = a.permissions.join(", ").toLowerCase();
                const bValue = b.permissions.join(", ").toLowerCase();
                if (isCurrentlySortedyourDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else if (columnName === "startDate" || columnName === "endDate") {
                const aValue = a[columnName];
                const bValue = b[columnName];
                const aDate = new Date(aValue).getTime();
                const bDate = new Date(bValue).getTime();
                if (isCurrentlySortedyourDelegatesDescending) {
                    return bDate - aDate;
                } else {
                    return aDate - bDate;
                }
            } else if (columnName === "notes") {
                const aValue = (a.loggedInUserNotes || "").toLowerCase();
                const bValue = (b.loggedInUserNotes || "").toLowerCase();
 
                if (isCurrentlySortedyourDelegatesDescending) {
                    return bValue.localeCompare(aValue);
                } else {
                    return aValue.localeCompare(bValue);
                }
            } else {
                // Handle sorting for other columns if needed
                return 0; // Add a default return statement for other columns
            }
        });
        setYourDelegationHistory(sortedyourDelegatesRecords);
    }
};
  const handleRowClick = (item, section) => {
    setSelectedRecord({ item, section });
    setIsDetailPanelOpen(true);
    setDelegationId(item.id);
  };
  useEffect(() => {
    if (parentContext) {
      let api = new DelegationAPI();
      api
        .getdelegationhistory(parentContext)
        .then((res: any) => {
          if (res.data && res.data !== "") {
            const YourDelegationCount = res.data.yourDelegationHistory;
            setYourDelegationHistory(YourDelegationCount);
            const OnBehalfDelegationCount = res.data.onBehalfDelegationHistory;

            setOnBehalfDelegationHistory(OnBehalfDelegationCount);
            const count = YourDelegationCount.length + OnBehalfDelegationCount.length;
            updateRecordCount(count);
          }
        })
        .catch((e: any) => {})
        .finally(() => {
          setIsPageLoading(false);
        });
    }
   
  }, [parentContext]);
  useEffect(() => {
    if (parentContext) {
     
    }
  }, [parentContext]);

  const HistoryHeader= <div><div style={{display:'inline-block'}}><h2 style={{ fontSize: '18px',marginBottom:'0',marginTop:'10px' }}>Delegation History     
                      </h2>
                      </div> 
                      <div style={{display:'inline', fontSize:'18px'}}>
                        <TooltipHost
                                    content="These are people you had delegated to, but their ability to act on your behalf has expired."
                                    delay={TooltipDelay.zero}
                                    directionalHint={DirectionalHint.topCenter}
                                    id="tooltip-Delegation-History"
                                    >
                                          <span
                                            role="tooltip"
                                            tabIndex={0}
                                            aria-label="Delegation History"
                                            aria-labelledby="tooltip-Delegation-History"
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter' || e.key === ' ') {
                                                e.preventDefault();
                                            
                                                // Cast e.target to HTMLElement
                                                const targetElement = e.target as HTMLElement;
                                            
                                                if (targetElement.click) {
                                                  targetElement.click(); // Now TypeScript will not complain
                                                }
                                              }
                                            }}
                                            // Add margin to create space between text and icon
                                            style={{ marginLeft: '8px' }} // Adjust the value as needed
                                            >                                          
                                    <Icon iconName="Info" className={infoIconClass.infoIcon} />
                                    </span>
                              </TooltipHost>
                      </div>                                
                      </div>
  const renderContent = () => {
    const label = flag ? "Loading" : "Wait...";
      const ariaLabel = flag ? "Loading" : "Please wait";
  
      let submitloader = isPageLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            backgroundColor: "rgba(229, 229, 229, 0.5)",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            id="reassignment-spinner"
            label={label}
            labelPosition="right"
            ariaLabel={ariaLabel}
            size={SpinnerSize.large}
          />
        </div>
      ) : null;
      if (isPageLoading) {
        return submitloader;
      }
    return (
      <div className={personaStyle.iconButton}>
        <Stack tokens={{ childrenGap: 5 }}>
            {yourDelegationHistory.length > 0 || onBehalfDelegationHistory.length > 0 ?(
                <div>
           {yourDelegationHistory.length > 0 && <Stack tokens={{ childrenGap: 5 }}>
                <div>
                    {HistoryHeader}
                    <DetailsList
                      items={yourDelegationHistory.map(
                    ({
                      id,
                      delegate,
                      partnerName,
                      permissions,
                      startDate,
                      endDate,
                      loggedInUserNotes,
                  }) => {
                      const [firstName, lastName] = delegate.name ? delegate.name.split(" ") : "";
                      const username = delegate.email ? delegate.email.split("@")[0] : "";
                      return {
                          id,
                          delegate: `${firstName} ${lastName}`,
                          application: partnerName ? partnerName : "app1",
                          permissions: permissions ? permissions.join(", ") : "",
                          startDate: formatDate(startDate),
                          endDate: formatDate(endDate),
                          notes: loggedInUserNotes,
                          username: username,
                      };
                  }
              )}
      
                  columns={columnsForyourDelegates}
                  compact={true}
                  ariaLabelForGrid="Delegation History Table"
                  styles={{
                    contentWrapper: {
                      maxHeight: 150,
                      height:'auto',
                      overflowX:'hidden'
                    }
                  }}
                  selectionMode={SelectionMode.none}
                  onItemInvoked={(item) => handleRowClick(item, "isyourDelegates")}
                  onRenderItemColumn={(item, index, column) => {
                    const value = "isyourDelegates";
                  
                    if (column.key === "delegate") {
                        return (
                            <TooltipHost content={item.username}>
                                <div id={`row_${item.id}`}>{item[column.fieldName]}</div>
                            </TooltipHost>
                        );
                    }
                    return item[column.fieldName];
                }}
                onRenderRow={(props) => {
                  const customRowProps = {
           ...props,
                   onClick: () => handleRowClick(props.item, "isyourDelegates"),
              };
                    return renderRow(customRowProps);
              }}

                      />
                </div>
                </Stack>
           }
            <Stack tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: 10 } }}>
          <div>
                                {onBehalfDelegationHistory.length > 0 ? (
 <div>
              <div>
                <div style={{display:'inline-block'}}>
                    <h2 style={{ fontSize: '18px',marginBottom:'0', marginTop:'10px' }}>On Behalf of Delegation History
                    </h2>
                </div>
                <div style={{display:'inline', fontSize:'18px'}}>
                      <TooltipHost
                      content="These are people you previously delegated to using your administrator role, but their delegations have expired."
                      delay={TooltipDelay.zero}
                      directionalHint={DirectionalHint.topCenter}
                      id="tooltip-OnBehalfof-delegation-history"
                    >
                      <span
                        role="tooltip"
                        tabIndex={0}
                        aria-label="On Behalf of Delegation History"
                        aria-labelledby="tooltip-OnBehalfof-delegation-history"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();

                            // Cast e.target to HTMLElement
                            const targetElement = e.target as HTMLElement;

                            if (targetElement.click) {
                              targetElement.click(); // Now TypeScript will not complain
                            }
                          }
                        }}
                        // Add margin to create space between text and icon
                        style={{ marginLeft: '8px' }} // Adjust the value as needed
                      >
                        <Icon iconName="Info" className={infoIconClass.infoIcon} />
                      </span>
                    </TooltipHost>
                </div>
              </div>
<DetailsList
items={onBehalfDelegationHistory.map(
    ({
        id,
        delegator, // delegatorName i add for the name it will  display on Delegator column
        delegate, // delegatename i add for the name it will  display on Delegate column
        partnerName,
        permissions,
        startDate,
        endDate,
        loggedInUserNotes,
    }) => {
        const [delegatorfirstName, delegatorlastName] = delegator.name ? delegator.name.split(" ") : "";
        const delegatorusername = delegator.email ? delegator.email.split("@")[0] : "";
        const [delegatefirstName, delegatelastName] = delegate.name ? delegate.name.split(" ") : "";
        const delegateusername = delegate.email ? delegate.email.split("@")[0] : "";
        return {
            id,
            delegator: `${delegatorfirstName} ${delegatorlastName}`,
            delegate: `${delegatefirstName} ${delegatelastName}`,
            application: partnerName ? partnerName : "app1",
            permissions: permissions ? permissions.join(", ") : "",
            startDate: formatDate(startDate), // Format the start date
            endDate: formatDate(endDate),
            notes: loggedInUserNotes,
            delegatorusername: delegatorusername,
            delegateusername: delegateusername,
        };
    }
)}
 
            columns={columnsForonBehalfDelegates}
            compact={true}
            styles={{
              contentWrapper: {
                maxHeight: 150,
                height:'auto',
                overflowX:"hidden"
              }
            }}
            selectionMode={SelectionMode.none}
            onItemInvoked={(item) => handleRowClick(item, "onBehalfDelegates")}
            onRenderItemColumn={(item, index, column) => {
              const value = "isonBehalfDelegates";
              if (column.key === "delegate") {
                  return (
                      <TooltipHost content={item.delegateusername}>
                          <div id={`row_${item.id}`}>{item[column.fieldName]}</div>
                      </TooltipHost>);
              }
              if (column.key === "delegator") {
                  return (
                      <TooltipHost content={item.delegatorusername}>
                          <div id={`row_${item.id}`}>{item[column.fieldName]}</div>
                      </TooltipHost>);
              }
              return item[column.fieldName];
          }}
          onRenderRow={(props) => {
            const customRowProps = {
     ...props,
             onClick: () => handleRowClick(props.item, "isyourDelegates"),
        };
              return renderRow(customRowProps);
        }}

          ariaLabelForGrid="On Behalf of Delegation History Table"
          /></div>): (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
            </div>
        )}
    </div>
            </Stack></div>
            ):<div><div style={{paddingTop:'5px'}}>{HistoryHeader}</div><div style={{ textAlign: "center" }}>
                        <EmptyResults message="You have no delegation history" />
              </div>
              </div>
            }
       </Stack>
        <DelegationAudit
          isOpen={isDetailPanelOpen}
          onClose={() => setIsDetailPanelOpen(false)}
          selectedRecord={selectedRecord}
          formatDate={formatDate}
          delegationId={delegationId}
          props={props}
        />
      </div>
    );
  };
  return <Stack>{renderContent()}</Stack>;
};
export const PostDelegates = injectIntl(PostDelegatesComponent);