import * as React from 'react';
import { Stack,TextField,ITextFieldStyles} from '@fluentui/react';
import { Image, IImageProps } from '@fluentui/react/lib/Image';

const textFieldStyles: Partial<ITextFieldStyles> = {fieldGroup:{border:'0px',textAlign:'center'}, field: {  color: '#333333',fontWeight: 600, width:400,textAlign:'center' } };

const imageProps: Partial<IImageProps> = {
  src: process.env.REACT_APP_WEB_IMAGE_PATH+'/images/empty-search.svg'
};

function EmptyResults(props: { message?: string }): React.ReactElement {
    const displayMessage = props.message? props.message : "No Results Found";

    const [emptyResultsRef, setEmptyResultsRef] = React.useState(null);

    React.useEffect(() => {
        if (emptyResultsRef) {
            emptyResultsRef.focus();
        }
    }, [emptyResultsRef]);


    return (
        <div style={{height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Stack horizontalAlign='center'>
                <Stack.Item grow>
                <Image {...imageProps} width={68} height={68} title="Empty Search Icon" aria-label="Empty Search Icon"  alt="Empty Search Icon" />
                </Stack.Item>
                <Stack.Item >
                 <Stack horizontalAlign='center'>
                    <TextField
                        value={displayMessage}
                        aria-label={displayMessage}
                        title={displayMessage}
                        tabIndex={0}
                        styles={textFieldStyles}
                    />
                     </Stack>    
                </Stack.Item>
            </Stack>
        </div>
    );
}


export { EmptyResults };