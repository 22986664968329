import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTitle: {
    id: 'delegationDashboard.pageTitle',
    defaultMessage: 'Delegation Management',
  },
  pivotItem2headerText: {
    id: 'delegationDashboard.pivotItem2headerText',
    defaultMessage: 'People You Support',
  },
  pivotItem1headerText: {
    id: 'delegationDashboard.pivotItem1headerText',
    defaultMessage: 'Active Delegations',
  },
  pastDelegationheaderText: {
    id: 'delegationDashboard.pastDelegationheaderText',
    defaultMessage: 'Delegation History',
  },
  pivotItem3headerText: {
    id: 'delegationDashboard.pivotItem3headerText',
    defaultMessage: 'Reassignments',
  },
  rightPanelheaderText: {
    id: 'delegationDashboard.rightPanelheaderText',
    defaultMessage: 'Add New Delegate',
  },
  rightPanelReassignmentheaderText: {
    id: 'delegationDashboard.rightPanelReassignmentheaderText',
    defaultMessage: 'Add New Reassignment',
  },
  rightEditPanelheaderText: {
    id: 'delegationDashboard.rightEditPanelheaderText',
    defaultMessage: 'Edit delegate',
  },
  buttonAddnewdelegateheaderText: {
    id: 'delegationDashboard.buttonAddnewdelegateheaderText',
    defaultMessage: 'Add new delegate',
  },
  addNewDelegateDelegateNormalPeoplePicker: {
    id: 'delegationDashboard.addNewDelegateDelegateNormalPeoplePicker',
    defaultMessage: 'Delegate',
  },
  addNewDelegatorDelegatorNormalPeoplePickerr: {
    id: 'delegationDashboard.addNewDelegatorDelegatorNormalPeoplePickerr',
    defaultMessage: 'Delegator',
  },
  addNewDelegateDelegateStartDate: {
    id: 'delegationDashboard.addNewDelegateDelegateStartDate',
    defaultMessage: 'Start Date',
  },
  addNewDelegateDelegateEndDate: {
    id: 'delegationDashboard.addNewDelegateDelegateEndDate',
    defaultMessage: 'End Date',
  },
  addNewDelegateDelegateDelegatorNotes: {
    id: 'delegationDashboard.addNewDelegateDelegateDelegatorNotes',
    defaultMessage: 'Delegator Notes',
  },
  addNewDelegateDelegateApplication: {
    id: 'delegationDashboard.addNewDelegateDelegateApplication',
    defaultMessage: 'Application',
    },
    addNewDelegateDelegatePermissions: {
        id: 'delegationDashboard.addNewDelegateDelegatePermissions',
        defaultMessage: 'Permissions',
    },
  reAssignFrom: {
    id: 'delegationDashboard.reAssignFrom',
    defaultMessage: 'Assigned From',
  },
  reAssignTo: {
    id: 'delegationDashboard.reAssignTo',
    defaultMessage: 'Assigned To',
  },
  noteReassignment: {
    id: 'delegationDashboard.noteReassignment',
    defaultMessage: 'Notes',
  },
  reassignmentApplication: {
    id: 'delegationDashboard.reassignmentApplication',
    defaultMessage: 'Application',
  },
  addNewDelegateDelegateAddButton: {
    id: 'delegationDashboard.addNewDelegateDelegateAddButton',
    defaultMessage: 'Add',
  },
  reassignmentAddButton: {
    id: 'delegationDashboard.reassignmentAddButton',
    defaultMessage: 'Reassign',
  },
  updateDelegateDelegateupdateButton: {
    id: 'delegationDashboard.updateDelegateDelegateupdateButton',
    defaultMessage: 'Update',
  },
  addNewDelegateDelegateCancelButton: {
    id: 'delegationDashboard.addNewDelegateDelegateCancelButton',
    defaultMessage: 'Cancel',
  },
  graphTokenText: {
    id: 'helloWorldPage.graphTokenText',
    defaultMessage: 'Generate Graph Token',
  },
  apiTokenText: {
    id: 'helloWorldPage.apiTokenText',
    defaultMessage: 'Generate API Token',
  },
});