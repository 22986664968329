import { initializeIcons } from '@uifabric/icons';
import React, { FC,useState,useEffect,useContext} from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { DelegationDashboard } from './delegationDashboard/delegationDashboard';
import { IDelegationDashboardProps } from './delegationDashboard.types';
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { getCurrentLocale, getCurrentTheme } from '../utils'
import { ThemeProvider } from "@fluentui/react-theme-provider";
import { AppDataServiceProvider } from '../provider';
import TelemetryContext, { TelemetryContextProvider } from "../context/TelemetryContext";

initializeIcons();
export const App: FC<IDelegationDashboardProps> = (props) => {
    const currentLocale = getCurrentLocale(props.parentContext);
    const currentTheme = getCurrentTheme(props.parentContext);
    const [isLoading, setIsLoading] = useState(true);
    const {initializeTelemetryClient} = useContext(TelemetryContext);
    useEffect(() => {
        // Simulate a loading delay for demonstration purposes
        const timer = setTimeout(() => {
            setIsLoading(false); // Set loading state to false after the delay
        }, 2000); // Adjust the delay time as needed
        // Clean up the timer on component unmount
        return () => clearTimeout(timer);
    }, []);
    return (
        <TelemetryContextProvider>
        <AppDataServiceProvider parentContext={props.parentContext} >
            <IntlProvider key={currentLocale} locale={currentLocale}>
                <ThemeProvider theme={currentTheme}>
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>                                        
                        <Spinner size={SpinnerSize.large} label="Loading"  labelPosition="right"
                        ariaLabel="Delegation Platfrom Loading"
                        />
                        </div>
                    ) : (
                    <DelegationDashboard parentContext={props.parentContext} updateRecordCount={props.updateRecordCount}
                    />
                    )}
                </ThemeProvider>
            </IntlProvider>
        </AppDataServiceProvider>
        </TelemetryContextProvider>
    );
};

App.propTypes = {
    supplierId: PropTypes.string,
    companyCode: PropTypes.string,
    parentContext: PropTypes.any,

};

export const DelegationDashboardApp = App;